import React from "react";
import styled from "styled-components";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const BackButtonCont = styled.div`
  position: relative;
  display: flex;
  a {
    position: ${(props) => (props.position ? "absolute" : "relative")};
    display: flex;
    color: white;
    font-size: 60px;
    .backButtonCont {
      background: #235789;
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const BackButton = ({ urlBack, position = false }) => {
  return (
    <BackButtonCont position={position}>
      <Link to={urlBack}>
        <div className="backButtonCont">
          <RiArrowGoBackLine />
        </div>
      </Link>
    </BackButtonCont>
  );
};
export default BackButton;
