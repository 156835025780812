import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../components/modules";
import BackButton from "../components/BackButton";
import { obtenerEncabezados, encabezadoDiaDeHoy } from "../consumo_apollo_client/clienteEncabezado";
import Breadcrumb from "../components/Breadcrumb";
import InputText from "../components/InputEncabezados";
import { Modal, Button } from "react-bootstrap";
import EditorEncabezados from "../pages/FormEditorEncabezados";
const EncabezadosCont = styled.div`
  display: flex;
  flex-direction: column;
  .row {
    height: 100%;
    display: flex;
    align-items: center;
    &-img {
      width: 100px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const MatutinoEncabezados = () => {
  const [encabezados, setEncabezados] = useState([]);
  const [headersValidated, setHeadersValidated] = useState([])
  const [show, setShow] = useState(false);
  let validationArray = [];

  const loadEncabezados = () => {
    obtenerEncabezados()
      .then(res => {setEncabezados(res)})
      .catch(e => setEncabezados([]));
    encabezadoDiaDeHoy()
      .then(res => {
      setHeadersValidated(res)})
      .catch(e => setHeadersValidated([]));
  };

  const handleClose = () => setShow(false);
  useEffect(() => {
    loadEncabezados();
  }, []);

  if (headersValidated) {
    validationArray = headersValidated.map(el => el.nombre)
  }

  return (
    <>
      <BackButton urlBack="/matutino" position={true} />
      <Container>
        <Breadcrumb text="Matutino/Encabezados" />
        <EncabezadosCont>
          <div>
            <button
              onClick={() => {
                setShow(true);
              }}
            >
              Ver encabezados aprobados
            </button>
          </div>
          {encabezados &&
            encabezados.map((elem) => {
              return (
                <div key={elem.nombre}>
                  <div className="row">
                    <div className="row-img">
                      <img src={elem.imagen} alt="" />
                    </div>
                    <a
                      href={elem.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Link
                    </a>
                  </div>
                  {validationArray.includes(elem.nombre) ? (
                    <div className="row">
                      <InputText type="text" load={loadEncabezados} item={elem} validation={true} />
                    </div>
                  ) : <div className="row">
                      <InputText type="text" load={loadEncabezados} item={elem} validation={false} />
                    </div>
                  }
                </div>
              );
            })}
        </EncabezadosCont>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Encabezados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorEncabezados />
        </Modal.Body>
        <Modal.Footer>
          <Button letiant="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MatutinoEncabezados;
