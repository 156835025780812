import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();

/**
 * Solo llama el metodo y resuelve con promesa o async
 * @return {Array} Arreglo de opiniones
 */

const obtenerOpiniones = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            opinionMany {
              _id
              titulo
              cuerpo
              categoria
              font
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.opinionMany);
      });
  });
};

const obtenerTodasLasOpinioneDiaDeHoy = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            opinionTodosDiadeHoy {
              titulo
              cuerpo
              categoria
              autor
              _id
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.opinionTodosDiadeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Solo llama el metodo y resuelve con promesa o async
 * @param datos
 * datos={id:"id de la opinion"}
 * @return Todos los campos de la opinion
 **/

const obtenerOpinionporId = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query opinionById($id: MongoID!) {
            opinionById(_id: $id) {
              _id
              titulo
              cuerpo
              categoria
              font
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.opinionById);
      });
  });
};

/**
   * Solo llama el metodo y resuelve con promesa o async el id del mismo
   * datos:{
   *  titulo: String,
      cuerpo: String,
      categoria: String,
      font: [String]
   * }
   */

const crearOpinion = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation opinionCreateOne($record: CreateOneOpinionInput!) {
            opinionCreateOne(record: $record) {
              recordId
              record {
                titulo
                cuerpo
                categoria
                autor
                _id
                updatedAt
                createdAt
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.opinionCreateOne);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Solo llama el metodo y resuelve con promesa o async
 * datos:{id:"id de la opinion"}
 * @return el ID de la opinion robada
 */
const eliminarOpinion = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation opinionRemoveById($id: MongoID!) {
            opinionRemoveById(_id: $id) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.opinionRemoveById);
      });
  });
};
/**
 * Solo llama el metodo y resuelve con promesa o async
 * data:{id:"ID de la opinion",... campos a actualizar clave:valor
 * @return ID de la opinion modificada
 */

const actualizarOpinion = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation opinionUpdateById($record: UpdateByIdOpinionInput!) {
            opinionUpdateById(record: $record) {
              recordId
              record {
                titulo
                cuerpo
                categoria
                autor
                _id
                updatedAt
                createdAt
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.opinionUpdateById);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const obtenerOpinionesPorCategoria = (categoria) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            opinionDiaDeHoy(tipo: "${categoria}") {
              _id
              titulo
              cuerpo
              categoria
              autor
              font
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.opinionDiaDeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/*
crearOpinion({
  titulo: "String",
  cuerpo: "String",
  categoria: "String",
  font: "String",
});

obtenerOpinionesPorCategoria("String", ["titulo", "font"]).then((res) => {
  console.log(res);
});
*/

export {
  obtenerOpiniones,
  obtenerOpinionporId,
  crearOpinion,
  actualizarOpinion,
  obtenerOpinionesPorCategoria,
  eliminarOpinion,
  obtenerTodasLasOpinioneDiaDeHoy,
};
