import React, { useState } from "react";
import InputText from "../components/InputText";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { actualizarOpinion } from "../consumo_apollo_client/clienteOpiniones";
import ButtonSubmit from "../components/ButtonSubmit";
import { tipos } from "../InformacionParaArticulos/clasificacion";
import { useForm, Controller } from "react-hook-form";
import { titleStyles } from "../styles/styles";
import { Form, ErrorMessage } from "../components/modules";
import { Editor } from "react-draft-wysiwyg";

const MatutinoPortadaGeneral = ({ info, actualizar, cerrar }) => {
  //Hooks
  const [loading, setLoading] = useState(false);
  const [categoria, setCategoria] = useState(info.categoria);
  const [opinion, setOpinion] = React.useState(
    EditorState.createWithContent(convertFromRaw(JSON.parse(info.cuerpo)))
  );
  const {
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset,
    errors: errors2,
  } = useForm({
    defaultValues: { cuerpo: null },
  });

  React.useEffect(() => {
    reset({
      titulo: info.titulo,
      cuerpo: info.cuerpo,
      autor: info.autor,
      categoria: info.categoria,
    });
  }, [reset, info]);

  //Submit para la creacion de la opinion
  const onSubmitData = (data) => {
    if (!loading) {
      setLoading(true);
      let record = {
        record: {
          _id: info._id,
          titulo: data.titulo,
          cuerpo: JSON.stringify(convertToRaw(opinion.getCurrentContent())),
          categoria: categoria,
          autor: "Edicion-MDM",
        },
      };
      actualizarOpinion(record)
        .then(() => {
          setLoading(false);
          actualizar();
          alert("Opinion actualizada");
          cerrar();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          alert("Error al actualizar opinion");
        });
    } else {
      alert("Espera a que se actualice la opinion");
    }
  };

  //Cierra y abre modal
  return (
    <Form onSubmit={handleSubmit2(onSubmitData)}>
      <div className="NOCont">
        <div className="NOCont-title">
          <h2>Modificar opinión</h2>
        </div>
        <div className="select-row h-auto">
          <div className="NSCont-row-input w-30">
            <select
              styles={titleStyles}
              onChange={(val) => {
                setCategoria(val.target.value);
              }}
              value={categoria}
              name="categoria"
            >
              {tipos.map((item, i) => {
                return (
                  <option key={i} value={item.clave}>
                    {item.nombre}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="NSCont-row">
          <div className="NSCont-row-input w-65">
            <InputText
              name="titulo"
              placeholder="Titulo"
              register={register2({ required: true })}
            />
          </div>
        </div>
        <ErrorMessage>
          {errors2.titulo?.type === "required" && "Es requerido"}
        </ErrorMessage>
        <div style={{ paddingBottom: 100 }} className="cont-form-textarea">
          <div className="cont-form-textarea-contTexarea">
            <Controller
              as={Editor}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "emoji",
                  "remove",
                  "history",
                ],
              }}
              editorState={opinion}
              onEditorStateChange={(e) => setOpinion(e)}
              name="cuerpo"
              control={control2}
              rules={{
                required: true,
              }}
            />
          </div>
          <ErrorMessage>
            {errors2.cuerpo?.type === "required" && "Es requerido"}
          </ErrorMessage>
        </div>
      </div>
      <ButtonSubmit text="Guardar resumen de opinión" bg="#2F2F2F" />
    </Form>
  );
};
export default MatutinoPortadaGeneral;
