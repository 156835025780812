import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache()

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`
})

const authLink = setContext((_, { headers }) => {
  const token = "EDITOR_DEV"
  return {
    headers: {
      ...headers,
      authorization: token
    }
  }
})


// const splitLink = split(({ query }) => {
//   const { kind, operation } = getMainDefinition(query)
//   return kind === 'OperationDefinition' && operation === 'subscription'
// }, wsLink, authLink.concat(httpLink))


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})

export const clearCache = () => {
  client.clearStore()
}

export default client