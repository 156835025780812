import React, { useState } from "react";
import styled from "styled-components";
import { crearEncabezado } from "../consumo_apollo_client/clienteEncabezado";
const InputTextCont = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #000000;
  input {
    font-family: "Oswald";
    font-size: 15px;
    width: 100%;
    border: none;
    color: #000000;
    &:focus {
      outline: none;
    }
  }
`;

const InputText = ({ item, load, validation }) => {
  const [value, setValue] = useState(item.titular);

  return (
    <InputTextCont>
      <input onChange={(e) => setValue(e.target.value)} value={value} />
      <button
        disabled={validation}
        onClick={() => {
          delete item.__typename;
          crearEncabezado({ record: item })
            .then(() => {
              load();
              alert("Encabezado creado");
            })
            .catch(() => alert("Error al crear encabezado"));
        }}
      >
        {validation ? 'Aprobado' : 'Aprobar'}
      </button>
    </InputTextCont>
  );
};
export default InputText;
