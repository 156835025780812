import React from "react";
import styled from "styled-components";
import Button from "./Button";

const MatutinoStructureCont = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .matutinoStrucCont {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    min-height: 600px;
    margin: auto;
    &-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      .p {
        font-size: 26px;
      }
    }
  }
`;

const MatutinoStructure = ({ portadaUrl, bulletsUrl }) => {
  return (
    <MatutinoStructureCont>
      <div className="matutinoStrucCont">
        <div className="matutinoStrucCont-button">
          <Button text="Portada" url={portadaUrl} />
        </div>
        <div className="matutinoStrucCont-button">
          <Button text="Bullets" url={bulletsUrl} />
        </div>
      </div>
    </MatutinoStructureCont>
  );
};
export default MatutinoStructure;
