import React, { useState } from "react";
import { useQuery } from "@apollo/client"
import ButtonSubmit from "../components/ButtonSubmit";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { ARTICULO_POR_CATEGORIA } from "../consumo_apollo_client/clienteArticulos";
import { crearPortada } from "../consumo_apollo_client/clientePortadas";
import { titleStyles } from "../styles/styles";

const MatutinoPortadaGeneral = ({ tipo }) => {

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      porcentaje1: "0",
      porcentaje2: "0",
      porcentaje3: "0",
      porcentaje4: "0",
      porcentaje5: "0",
      titleSelect1: "",
      titleSelect2: "",
      titleSelect3: "",
      titleSelect4: "",
      titleSelect5: "",
      Destacado: "",
    },
  });

  const [porcentajeTrue, setPorcentajeTrue] = useState(true);
  const onSubmit = async (data) => {
    let porcentGeneral =
      parseInt(data.porcentaje1) +
      parseInt(data.porcentaje2) +
      parseInt(data.porcentaje3) +
      parseInt(data.porcentaje4) +
      parseInt(data.porcentaje5);
    if (porcentGeneral === 100) {
      setPorcentajeTrue(true);
      const portada = {
        categoria: tipo,
        pie: [
          {
            articulo: data.titleSelect1.value,
            porcentaje: parseInt(data.porcentaje1),
          },
          {
            articulo: data.titleSelect2.value,
            porcentaje: parseInt(data.porcentaje2),
          },
          {
            articulo: data.titleSelect3.value,
            porcentaje: parseInt(data.porcentaje3),
          },
          {
            articulo: data.titleSelect4.value,
            porcentaje: parseInt(data.porcentaje4),
          },
          {
            articulo: data.titleSelect5.value,
            porcentaje: parseInt(data.porcentaje5),
          },
        ],
        destacado: data.Destacado.value,
      };
      try {
        await crearPortada({ record: portada });
        alert("Portada guardada");
        window.location.reload();
      } catch (e) {
        alert("Error al guardar portada");
      }
    } else {
      setPorcentajeTrue(false);
    }
  };
  const {loading, error, data } = useQuery(ARTICULO_POR_CATEGORIA, {variables:{tipo}})
  if (loading) return <p>Loading...</p>
  if (error) return <p>Ocurrió un error</p>

  return (
    <div className="cont">
      <form className="cont-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="breadcrumb">
          <span>Matutino/Portada/{tipo}</span>
        </div>
        <div className="errorReport">
          <span className="error">
            {porcentajeTrue
              ? " "
              : "La suma de de todos los porcentajes debe ser de 100%"}
          </span>
        </div>
        <div className="cont-form-pieGeneral">
          <div className="cont-form-pieGeneral-title">
            <h2>Pie general</h2>
          </div>
          <div className="cont-form-pieGeneral-selects">
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
                  name="titleSelect1"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>
              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje1"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
                  name="titleSelect2"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje2"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
                  name="titleSelect3"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje3"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
                  name="titleSelect4"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje4"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
                  name="titleSelect5"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje5"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cont-form-textarea">
          <div className="cont-form-textarea-title">
            <h2>Articulo destacado</h2>
          </div>
          <div className="cont-form-semaforos-row-input">
            <Controller
              as={Select}
              options={data.articuloDiadeHoy.map(e=> ({label: e.titulo, value:e._id, ...e}) )}
              name="Destacado"
              control={control}
              styles={titleStyles}
              placeholder="Título de texto"
              rules={{
                required: true,
              }}
            />
          </div>
        </div>
        <div className="cont-form-button">
          <ButtonSubmit text="Guardar" />
        </div>
      </form>
    </div>
  );
};
export default MatutinoPortadaGeneral;
