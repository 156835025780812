import React from "react";
import styled from "styled-components";

const BreadcrumbCont = styled.div`
  display: flex;
  width: 100%;
  h2 {
    margin: 0;
    font-family: "Oswald";
    font-size: 72px;
  }
`;

const Breadcrumb = ({ text }) => {
  return (
    <BreadcrumbCont>
      <h2> {text} </h2>
    </BreadcrumbCont>
  );
};
export default Breadcrumb;
