import React, { useState } from "react";
import styled from "styled-components";
import {
  eliminarEncabezado,
  actualizarEncabezado,
} from "../consumo_apollo_client/clienteEncabezado";
const InputTextCont = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #000000;
  input {
    font-family: "Oswald";
    font-size: 15px;
    width: 100%;
    border: none;
    color: #000000;
    &:focus {
      outline: none;
    }
  }
`;

const InputText = ({ item, load }) => {
  const [value, setValue] = useState(item.titular);
  delete item.__typename;
  return (
    <InputTextCont>
      <input onChange={(e) => setValue(e.target.value)} value={value} />
      <button
        onClick={() => {
          eliminarEncabezado({ id: item._id })
            .then(() => {
              load();
              alert("Encabezado eliminado");
            })
            .catch(() => {
              alert("Error al crear encabezado");
            });
        }}
      >
        Eliminar
      </button>
      <button
        onClick={() => {
          item.titular = value;
          actualizarEncabezado({ record: item })
            .then(() => {
              load();
              alert("Encabezado actualizado");
            })
            .catch(() => {
              alert("Error al crear encabezado");
            });
        }}
      >
        Actualizar
      </button>
    </InputTextCont>
  );
};
export default InputText;
