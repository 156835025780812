import React, { useState } from "react";
import { Container, Table, Form, ErrorMessage } from "../components/modules";
import BackButton from "../components/BackButton";
import Breadcrumb from "../components/Breadcrumb";
import { clasificacion } from "../InformacionParaArticulos/clasificacion";
import { estados } from "../InformacionParaArticulos/estados";
import ButtonSubmit from "../components/ButtonSubmit";
import { titleStyles } from "../styles/styles";
import InputText from "../components/InputText";
import { useForm, Controller } from "react-hook-form";
import { Modal, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { tipos } from "../InformacionParaArticulos/clasificacion";
import { Editor } from "react-draft-wysiwyg";
import { nombres_de_medios } from "../InformacionParaArticulos/nombres_medios_informacion";
import RowTable from "../components/modules/RowTable";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import { obtenerArticulos } from "../consumo_apollo_client/clientArticulosAPI";
import { medios_de_informacion } from "../InformacionParaArticulos/tipos_medios_de_informacion";
import OpinionPreview from "../components/OpinionPreview";
import {
  crearOpinion,
  obtenerTodasLasOpinioneDiaDeHoy,
} from "../consumo_apollo_client/clienteOpiniones";
import {
  ArticulosCreados,
  NuevaOpinion,
  NuevoArticulo,
  SintetizarTabla,
} from "./Estilos/OpinionEstilos";
import FormEditor from "./FormEditorOpinion";

const MatutinoArticulos = () => {
  //Hooks
  const [item, setItem] = useState(undefined);
  const [show, setShow] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [opiniones, setOpiniones] = useState([]);
  const [tableCont, settableCont] = useState([]);
  const [nombres_medios, setNombres_medios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opinion, setOpinion] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );

  const [ search, setSearch ] = useState('')

  //Obteniendo opiniones
  const obtenerOpiniones = () => {
    obtenerTodasLasOpinioneDiaDeHoy().then((res) => {
      setOpiniones(res);
    });
  };
  //Medios
  const fetchMedios = async () => {
    nombres_de_medios()
      .then((res) => {
        setNombres_medios(res);
      })
      .catch(() => {
        setNombres_medios([]);
      });
  };
  //Obtiene las opiniones
  React.useEffect(() => {
    obtenerOpiniones();
    fetchMedios();
  }, []);
  //Formularios
  const { register, handleSubmit } = useForm({
    defaultValues: {},
  });

  //Nueva opinion
  const {
    reset: reset2,
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    errors: errors2,
  } = useForm({
    defaultValues: { cuerpo: null },
  });

  //Validador busqueda
  const {
    register: registerNA,
    control: controlNA,
    handleSubmit: handleSubmitNA,
    errors: errorsNA,
  } = useForm({
    defaultValues: {
      "tags-inc": [],
      "tags-exc": [],
      ambito: "",
      medio: "",
      estado: "",
      nombreMedio: "",
    },
  });

  /* -----  DOC:  LOGIC TO GET OPINIONS ----- */
  const onSubmit = async (data) => {
    if (!loading) {
      let palabras = "x";
      let nombres_medio = [];
      let todos = false;
      for (let m of data.nombreMedio) {
        if (m.clave === 0) {
          todos = true;
          break;
        }
      }
      if (todos) {
        for (let m of nombres_medios) {
          nombres_medio.push(parseInt(m.clave));
        }
        nombres_medio.shift();
      } else {
        for (let m of data.nombreMedio) {
          nombres_medio.push(parseInt(m.clave));
        }
      }
      data = {
        palabras: palabras,
        fechaInicio: data.fechaInicial.replace("T", " ") + ":00",
        fechaFinal: data.fechaFinal.replace("T", " ") + ":00",
        ambito: [parseInt(data.ambito.clave)],
        nombre_medio: nombres_medio,
        tipo_medio: parseInt(data.medio.clave),
        clasificacion: "2",
        estado: parseInt(data.estado.clave),
      };
      try {
        setLoading(true);
        let info = await obtenerArticulos(data);
        settableCont(info.data.buscarArticulos);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  //Submit para la creacion de la opinion
  const onSubmitTable = (data) => {
    let texto = "";
    for (let t of data.sintetizar) {
      texto = texto + t;
    }
    setOpinion(
      EditorState.createWithContent(ContentState.createFromText(texto))
    );
  };
  //Submit para la creacion de la opinion
  const onSubmitData = (data) => {
    let record = {
      record: {
        titulo: data.titulo,
        cuerpo: JSON.stringify(convertToRaw(opinion.getCurrentContent())),
        categoria: data.categoria.clave,
        autor: "Edicion-MDM",
      },
    };
    crearOpinion(record)
      .then(() => {
        reset2({ titulo: "", cuerpo: null });
        setOpinion(null);
        obtenerOpiniones();
        alert("Opinión creada");
      })
      .catch((e) => {
        alert("Error al crear opinión");
      });
  };

  //Preview
  const onSubmitDataPreview = (data) => {
    let record = {
      titulo: data.titulo,
      cuerpo: JSON.stringify(convertToRaw(opinion.getCurrentContent())),
      categoria: data.categoria.clave,
      autor: "Edicion-MDM",
    };
    console.log(JSON.stringify(record));
    setItem(record);
    setShowPreview(true);
  };

  //Cierra y abre modal
  const handleClose = () => setShow(false);
  const handleClosePreview = () => setShowPreview(false);
  console.log(tableCont)
  return (
    <>
      <BackButton urlBack="/matutino" position={true} />
      <Container>
        <Breadcrumb text="Matutino/Opinion" />
        <ArticulosCreados>
          <div className="title">
            <h3> Opiniónes creadas </h3>
          </div>
          <div className="text">
            {opiniones.length === 0 ? (
              <p> No hay ninguna opinion creada todavía.. </p>
            ) : (
              <div className="container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>Categoria</th>
                      <th>Autor</th>
                      <th>Visualizar</th>
                      <th>Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {opiniones.map((elem, i) => {
                      return (
                        <tr key={i}>
                          <td>{elem.titulo}</td>
                          <td>{elem.categoria}</td>
                          <td>{elem.autor}</td>
                          <td
                            style={{ color: "#235789" }}
                            onClick={() => {
                              setShowPreview(true);
                              setItem(elem);
                            }}
                          >
                            <p>Ver</p>
                          </td>
                          <td
                            style={{ color: "#235789" }}
                            onClick={() => {
                              setShow(true);
                              setItem(elem);
                            }}
                          >
                            <p>Editar</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ArticulosCreados>
        <Container margin="0" flexDirection="row">
          <NuevoArticulo>
            <div className="title">
              <h3>Filtros</h3>
            </div>
            <form
              onError={() => {
                console.log(errorsNA);
              }}
              key={1}
              onSubmit={handleSubmitNA(onSubmit)}
            >
              <div className="dateTime">
                Fecha inicial:
                <div className="dateTime-row">
                  <div className="dateTime-row-input">
                    <input
                      type="datetime-local"
                      control={controlNA}
                      name="fechaInicial"
                      ref={registerNA({ required: true })}
                    />
                  </div>
                </div>
                <ErrorMessage>
                  {errorsNA.fechaInicial?.type === "required" && "Es requerido"}
                </ErrorMessage>
                Fecha final:
                <div className="dateTime-row">
                  <div className="dateTime-row-input">
                    <input
                      type="datetime-local"
                      control={controlNA}
                      name="fechaFinal"
                      ref={registerNA({ required: true })}
                    />
                  </div>
                </div>
                <ErrorMessage>
                  {errorsNA.fechaFinal?.type === "required" && "Es requerido"}
                </ErrorMessage>
              </div>
              <div className="select">
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={estados}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="estado"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un estado"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.estado?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={medios_de_informacion}
                    getOptionLabel={(options) => options.descripcion}
                    getOptionValue={(options) => options.clave}
                    name="medio"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un medio"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.medio?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={nombres_medios}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="nombreMedio"
                    isMulti
                    isClearable={true}
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un nombre de medio"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.nombreMedio?.type === "required" &&
                      "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={clasificacion}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="ambito"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona una clasificación"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.ambito?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
              </div>
              <ButtonSubmit text="Buscar" bg="#2F2F2F" />
            </form>
          </NuevoArticulo>
          {/*  DOC:  SEARCH RESULTS TABLE */}        
          {tableCont.length !== 0 ? (
            <SintetizarTabla>
              <div 
                style={{
                  width: '100%',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 20px'
                }
              }>
                <h6
                  style={{
                    marginBottom: '0',
                    marginRight: '10px'
                  }}
                >
                  FILTRAR
                </h6>
                <input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  style={{
                    width: '100%',
                    height: '40px',
                    padding: '0 10px'
                  }}
                />
              </div>
              <div className="tablaHeader">
                <div className="tablaHeader-row">
                  <div className="tablaHeader-row-check"></div>
                  <div className="tablaHeader-row-titulo">
                    <p>Título</p>
                  </div>
                  <div className="tablaHeader-row-medio">
                    <p>Medio</p>
                  </div>
                  <div className="tablaHeader-row-tipo">
                    <p>Tipo</p>
                  </div>
                </div>
              </div>
              <Form onSubmit={handleSubmit(onSubmitTable)}>
                <Table>
                  {tableCont
                  .filter(({ titulo }) => titulo.toLowerCase().includes(search.toLowerCase()))
                  .map((data, i) => (
                    <RowTable
                      key={i}
                      name={"sintetizar"}
                      value={data.texto}
                      forId={`checkTG${i}`}
                      titulo={data.titulo}
                      medio={data.nombre_medio}
                      tipo={data.tipo_medio}
                      register={register}
                    />
                  ))}
                </Table>
                <div className="button">
                  <ButtonSubmit text="Sintetizar" bg="#FA4443" />
                </div>
              </Form>
            </SintetizarTabla>
          ) : (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <>
                  {<p>No hay resultados para mostrar</p>}
                  {loading && <Spinner animation="border" />}
                </>
              </div>
            </>
          )}
        </Container>
        <Container margin="0 0 80px 0">
          <Form>
            <NuevaOpinion>
              <div className="NOCont">
                <div className="NOCont-title">
                  <h2>Nueva opinión</h2>
                </div>
                <div className="select">
                  <div className="select-row h-auto">
                    <Controller
                      as={Select}
                      options={tipos}
                      getOptionLabel={(options) => options.nombre}
                      getOptionValue={(options) => options.clave}
                      name="categoria"
                      control={control2}
                      styles={titleStyles}
                      placeholder="Selecciona una categoría"
                      rules={{
                        required: true,
                      }}
                    />
                    <ErrorMessage>
                      {errors2.categoria?.type === "required" && "Es requerido"}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="NSCont-row">
                  <div className="NSCont-row-input w-65">
                    <InputText
                      name="titulo"
                      placeholder="Titulo"
                      register={register2({ required: true })}
                    />
                  </div>
                </div>
                <ErrorMessage>
                  {errors2.titulo?.type === "required" && "Es requerido"}
                </ErrorMessage>
                <div
                  style={{ paddingBottom: 100 }}
                  className="cont-form-textarea"
                >
                  <div className="cont-form-textarea-contTexarea">
                    <Controller
                      as={Editor}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "emoji",
                          "remove",
                          "history",
                        ],
                      }}
                      editorState={opinion}
                      onEditorStateChange={(e) => setOpinion(e)}
                      name="cuerpo"
                      control={control2}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>
                  <ErrorMessage>
                    {errors2.cuerpo?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
              </div>
              <ButtonSubmit
                onClick={handleSubmit2(onSubmitDataPreview)}
                text="Previsualizar"
                bg="#2F2F2F"
              />
              <ButtonSubmit
                onClick={handleSubmit2(onSubmitData)}
                text="Guardar resumen de opinión"
                bg="#2F2F2F"
              />
            </NuevaOpinion>
          </Form>
        </Container>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Opinión</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {item !== undefined && (
              <FormEditor
                actualizar={obtenerOpiniones}
                info={item}
                cerrar={handleClose}
              ></FormEditor>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button letiant="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          show={showPreview}
          onHide={handleClosePreview}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Opinión</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {item !== undefined && <OpinionPreview opinion={item} />}
          </Modal.Body>
          <Modal.Footer>
            <Button letiant="primary" onClick={handleClosePreview}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};
export default MatutinoArticulos;
