import React from "react";
import styled from "styled-components";
import Boletines from "../components/Boletines";
import VerticalText from "../components/VerticalText";
import LogoutBtn from '../components/LogoutBtn'

const HomePage = styled.div`
  display: flex;
  .height50 {
    height: 70px;
  }
  .mx-10vw {
    margin: 50px 10vw;
  }
  .p-0 {
    padding: 0;
  }
  @media (max-width: 768px) {
    .mx-10vw {
      margin: 50px 5vw;
    }
  }
`;

const Home = () => {
  return (
    <HomePage id="top">
      <VerticalText text="boletines" />
      <Boletines
        btnMananera={{
          url: "/mañanera/",
          text: "mañanera",
          colorBg: "#FA4443",
          colorText: "#FFFFFF",
          colorBorder: "#FA4443",
        }}
        btnEspecial={{
          url: "/",
          text: "especiales",
          colorBg: "#297A8E",
          colorText: "#FFFFFF",
          colorBorder: "#297A8E",
        }}
        btnMatutina={{
          url: "matutino",
          text: "Boletín Matutino",
          colorBg: "#FFE061",
          colorText: "#000000",
          colorBorder: "#FFE061",
        }}
        btnCovid={{
          url: "/",
          text: "Covid",
          colorBg: "#9D9D9F",
          colorText: "#FFFFFF",
          colorBorder: "#9D9D9F",
        }}
      />
      <LogoutBtn/>
    </HomePage>
  );
};
export default Home;
