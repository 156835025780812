import styled from "styled-components";
const ArticulosCreados = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  .title {
    display: flex;
    h3 {
      margin: 0;
      font-family: "Oswald";
      font-size: 26px;
    }
  }
  .text {
    display: flex;
    margin-top: 30px;
    p {
      margin: 0;
      font-size: 15px;
    }
  }
`;

const NuevoArticulo = styled.div `
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: auto;
  .error {
    height: 18px;
    width: 100%;
    display: flex;
    p {
      margin: 0;
      color: #fa4443;
      font-size: 13px;
      font-family: "Oswald";
    }
  }
  .title {
    display: flex;
    h3 {
      font-family: "Oswald";
      font-size: 26px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .dateTime {
      display: flex;
      flex-direction: column;
      &-row {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        &-input {
          display: flex;
          width: 100%;
          border-bottom: 1px solid black;
          height: 35px;
          input {
            width: 100%;
            border: none;
            font-size: 15px;
            font-family: "Oswald";
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      &-row {
        display: flex;
        &-input {
          display: flex;
          width: 33.33%;
          margin: 10px 0;
          align-items: center;
          span {
            margin-left: 10px;
            font-family: "Oswald";
            font-size: 15px;
          }
        }
      }
    }
    .select {
      display: flex;
      flex-direction: column;
      .h-auto {
        height: auto;
      }
      &-row {
        display: flex;
        flex-direction: column;
        height: 40px;
        margin: 10px 0;
      }
    }
  }
`;
const SintetizarTabla = styled.div `
  background: rgba(157, 157, 159, 0.2);
  display: flex;
  flex-direction: column;
  width: 60%;
  .tablaHeader {
    display: flex;
    background: white;
    width: 100%;
    height: 68px;
    &-row {
      display: flex;
      padding: 0 50px;
      width: 100%;
      &-check {
        width: 40px;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-titulo {
        width: 65%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-medio {
        width: 20%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-tipo {
        width: 15%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .button {
    display: flex;
    padding: 50px;
  }
`;

const NuevaOpinion = styled.div `
  display: flex;
  flex-direction: column;
  .NOCont {
    display: flex;
    flex-direction: column;
    &-title {
      font-family: Oswald;
      font-size: 26px;
      text-align: left;
      h2 {
        margin-bottom: 20px;
        margin-top: 80px;
      }
    }
    &-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      textarea {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        border: 2px solid black;
        font-family: Oswald;
        font-size: 14px;
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
    }
  }
`;


export {
  ArticulosCreados,
  NuevaOpinion,
  NuevoArticulo,
  SintetizarTabla,
}