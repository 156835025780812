import React from "react";
import styled from "styled-components";

const ButtonSubmitCont = styled.button`
  display: flex;
  background: ${(props) => props.bgB};
  color: white;
  height: ${(props) => props.heightB};
  width: ${(props) => props.widthB};
  font-family: "Oswald";
  font-size: ${(props) => props.fontSizeB};
  align-items: center;
  justify-content: center;
  border: none;
  // disabled: true;
  border-radius: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ButtonSubmit = ({
  onClick,
  text = "Enviar",
  height = "92px",
  width = "100%",
  fontSize = "42px",
  bg = "#000000",
  // disabled
}) => {
  return (
    <ButtonSubmitCont
      onClick={onClick}
      heightB={height}
      widthB={width}
      fontSizeB={fontSize}
      bgB={bg}
      // disabled={true}
    >
      {text}
    </ButtonSubmitCont>
  );
};
export default ButtonSubmit;
