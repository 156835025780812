import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonCont = styled.button`
  border: 3px solid ${(props) => props.colorBorder};
  padding: ${(props) => props.paddingB};
  text-align: center;
  width: ${(props) => props.widthB};
  background: ${(props) => props.colorBg};
  font-size: ${(props) => props.fontSizeB};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  p {
    text-transform: lowercase;
    color: ${(props) => props.colorText};
    margin: 0;
    font-family: "Oswald";
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const Button = ({
  text = "button",
  colorText = "#235789",
  colorBg = "#FFFFFF",
  colorBorder = "#235789",
  url = "/",
  fontSize = "42px",
  width = "310px",
  padding = "16px 0px",
}) => {
  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <ButtonCont
        colorBorder={colorBorder}
        colorText={colorText}
        colorBg={colorBg}
        widthB={width}
        fontSizeB={fontSize}
        paddingB={padding}
      >
        <p> {text} </p>
      </ButtonCont>
    </Link>
  );
};

export default Button;
