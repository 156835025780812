import styled from "styled-components";

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => (!props.bg ? "" : props.bg)};
  margin: 30px 0;
  height: 468px;
  overflow: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    !props.flexDirection ? "column" : props.flexDirection};
  margin: ${(props) => (!props.margin ? "0 10%" : props.margin)};
  color: black;
`;
export const ErrorMessage = styled.span`
  height: 18px;
  width: 100%;
  display: flex;
  margin: 0;
  color: #fa4443;
  font-size: 13px;
  font-family: "Oswald";
`;
