import axios from 'axios'

const baseURL = process.env.REACT_APP_SERVER_ENDPOINT

const service = axios.create({ baseURL, withCredentials: true })

export const allUsers = async () => {
  const {data: users} = await service.get('/allusers')
  return(users)
}

export const removeUser = async userId => {
  await service.delete(`/deleteuser/${userId}`)
  return true
}