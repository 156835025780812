import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../components/modules";
import { encabezadoDiaDeHoy } from "../consumo_apollo_client/clienteEncabezado";
import InputText from "../components/InputEncabezadosEditor";
import { Modal, Button } from "react-bootstrap";
const EncabezadosCont = styled.div`
  display: flex;
  flex-direction: column;
  .row {
    height: 100%;
    display: flex;
    align-items: center;
    &-img {
      width: 100px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const MatutinoEncabezados = () => {
  const [encabezados, setEncabezados] = useState([]);
  const [show, setShow] = useState(false);

  const loadEncabezados = () => {
    encabezadoDiaDeHoy()
      .then((res) => {
        setEncabezados(res);
      })
      .catch((e) => {
        setEncabezados([]);
      });
  };
  useEffect(() => {
    loadEncabezados();
  }, []);
  const handleClose = () => setShow(false);
  if (encabezados.length === 0)
    return <p className="row-input">No hay encabezados para mostrar</p>;
  return (
    <>
      <Container>
        <EncabezadosCont>
          {encabezados.sort((a, b) => a.indice - b.indice).map((elem, index) => {
            return (
              <div key={elem.nombre + index}>
                <div className="row">
                  <div className="row-img">
                    <img src={elem.imagen} alt="" />
                  </div>
                  <a href={elem.link} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </div>
                <div className="row">
                  <InputText type="text" load={loadEncabezados} item={elem} />
                </div>
              </div>
            );
          })}
        </EncabezadosCont>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Encabezados</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button letiant="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MatutinoEncabezados;
