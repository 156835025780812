import React, { useState } from "react";
import styled from "styled-components";
import InputCheckbox from "../InputCheckbox";

const RowTableStyles = styled.div`
  transition: ease-in-out 0.4s;
  background: ${(props) =>
    props.bgCheck ? "rgba(255, 255, 255, 0)" : "rgba(255, 255, 255, 1)"};
  display: flex;
  padding: 3px 50px 3px 50px;
  margin: 2px 0px;
  .tabla-check {
    margin-right: 10px;
  }
  .text {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    justify-content: space-between;
    width: 100%;
    &-titulo {
      width: 65%;
      text-align: left;
      overflow: hidden;
      margin-right: 10px;
    }
    &-medio {
      width: 20%;
      text-align: left;
      margin-right: 10px;
    }
    &-tipo {
      width: 15%;
      text-align: left;
      overflow: hidden;
      margin-right: 10px;
    }
  }
`;

const RowTable = ({ name, forId, value, titulo, medio, tipo, register }) => {
  const [checkState, setCheckState] = useState(true);
  return (
    <RowTableStyles bgCheck={checkState}>
      <div className="tabla-check">
        <InputCheckbox
          name={name}
          forId={forId}
          value={value}
          onChange={() => setCheckState(!checkState)}
          register={register}
        />
      </div>
      <div className="text">
        <div className="text-titulo"> {titulo}</div>
        <div className="text-medio"> {medio} </div>
        <div className="text-tipo"> {tipo} </div>
      </div>
    </RowTableStyles>
  );
};
export default RowTable;
