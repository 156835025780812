import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();

const crearSemaforos = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation semaforoCreateMany($records: [CreateManySemaforoInput!]!) {
            semaforoCreateMany(records: $records) {
              recordIds
              records {
                categoria
                luces {
                  articulo
                  color
                }
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.semaforoCreateMany);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { crearSemaforos };
