import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from '../Context/index'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userCtx } = useContext(Context)
  return (
    <Route {...rest} render={
      props => (userCtx? <Component {...rest} {...props} /> : <Redirect to="/login" />)
    } />
  )
}

export default PrivateRoute
