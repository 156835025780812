import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import { Modal, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import InputText from "../components/InputText";
import FormEditor from "./FormEditorBullet";
import { ErrorMessage } from "../components/modules";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { NuevaOpinion, ArticulosCreados } from "./Estilos/OpinionEstilos";
import {
  crearBullet,
  obtenerBullets,
  EliminarBullet,
} from "../consumo_apollo_client/clienteBullets";

const MatutinoArticulos = () => {
  const { register, control, handleSubmit, reset, errors: error } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: "cuerpo",
    reValidateMode: "onChange",
  });
  const [opiniones, setOpiniones] = useState([]);
  const [item, setItem] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  useEffect(() => {
    append({
      titulo: "",
      cuerpo: null,
    });
    cargarBullets();// eslint-disable-next-line
  }, []);

  const cargarBullets = () => {
    obtenerBullets()
      .then((res) => {
        setOpiniones(res);
      })
      .catch((err) => {
        setOpiniones([]);
      });
  };
  return (
    <>
      <BackButton urlBack="/mañanera/" position={true} />
      <section>
        <ArticulosCreados>
          <div className="title">
            <h3> Bullets creadas</h3>
          </div>
          <div className="text">
            {opiniones.length === 0 ? (
              <p> No hay ninguna bullet creada todavía.. </p>
            ) : (
              <div className="container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bullet</th>
                      <th>Autor</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {opiniones.map((elem, i) => {
                      return (
                        <tr key={elem._id}>
                          <td>{elem.titulo}</td>
                          <td>{elem.autor ? elem.autor : "Editorial MDM"}</td>
                          <td
                            style={{ color: "#235789" }}
                            onClick={() => {
                              setShow(true);
                              setItem(elem);
                            }}
                          >
                            <p>Editar</p>
                          </td>
                          <td
                            style={{ color: "#235789" }}
                            onClick={() => {
                              EliminarBullet({ id: elem._id })
                                .then(() => {
                                  // alert("Bullet eliminado");
                                  cargarBullets();
                                })
                                .catch((e) => {
                                  console.log(e)
                                });
                            }}
                          >
                            <p>Eliminar</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ArticulosCreados>
        <NuevaOpinion>
          <div className="NOCont">
            <div className="NOCont-title">
              <h2>Crear bullets</h2>
            </div>
            <form
              onSubmit={handleSubmit((data) => {
                data.cuerpo = JSON.stringify(data.cuerpo);
                let dataSubmit = { record: data };
                crearBullet(dataSubmit)
                  .then(() => {
                    reset({ titulo: "" });
                    cargarBullets();
                    alert("Bullet creado");
                    append({
                      titulo: "",
                      cuerpo: null,
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                    alert("Error al crear bullet");
                  });
              })}
            >
              <InputText
                name={"titulo"}
                register={register({ required: true })}
                defaultValue={""}
                placeholder={"Titulo"}
              />
              <ErrorMessage>
                {error["titulo"]?.type === "required" && "Es requerido"}
              </ErrorMessage>
              {fields &&
                fields.map((item, index) => (
                  <div key={item.id}>
                    <Controller
                      as={Editor}
                      rules={{
                        required: true,
                      }}
                      name={`cuerpo.${index}`}
                      control={control}
                      defaultValue={item.cuerpo}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "emoji",
                          "remove",
                          "history",
                        ],
                      }}
                    />
                    <ErrorMessage>
                      {error["cuerpo"]?.[index]?.type === "required" &&
                        "Es requerido"}
                    </ErrorMessage>
                    <button type="button" onClick={() => remove(index)}>
                      Borrar sub-bullet
                    </button>
                    {index === fields.length - 1 && (
                      <button
                        type="button"
                        onClick={() =>
                          append({
                            titulo: "",
                            cuerpo: null,
                          })
                        }
                      >
                        Añadir otra
                      </button>
                    )}
                  </div>
                ))}
              <input type="submit" />
            </form>
          </div>
        </NuevaOpinion>
      </section>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Articulo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {item !== undefined && (
              <FormEditor
                actualizar={cargarBullets}
                cerrar={handleClose}
                info={item}
              ></FormEditor>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button letiant="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};
export default MatutinoArticulos;
