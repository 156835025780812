import React, { useState } from "react";
import ButtonSubmit from "../components/ButtonSubmit";
import { useForm, Controller } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import InputRadio from "../components/InputRadio";
import { obtenerTodosLosArticulosHoy } from "../consumo_apollo_client/clienteArticulos";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { titleStyles } from "../styles/styles";
import { crearSemaforos } from "../consumo_apollo_client/clienteSemaforos";
import { crearPortada } from "../consumo_apollo_client/clientePortadas";

const MatutinoPortadaGeneral = ({ tipo }) => {
  const [optionTitles, setArticulos] = useState([]);
  const [nacional, setNacional] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );
  const [internacional, setInternacional] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );
  
  const procesarArticulos = () => {
      obtenerTodosLosArticulosHoy()
      .then((res) => {
        console.log(res)
        if (res) {
          let articulos_dummy = [];
          for (let art of res) {
            articulos_dummy.push({ 
              label: art.titulo, 
              value: art._id, 
              category: art.categoria
            });
          }
          setArticulos(articulos_dummy);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    
  };

  React.useEffect(() => {
    console.log("hey")
    procesarArticulos();
  }, []);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      porcentaje1: "0",
      porcentaje2: "0",
      porcentaje3: "0",
      porcentaje4: "0",
      porcentaje5: "0",
      titleSelect1: "",
      titleSelect2: "",
      titleSelect3: "",
      titleSelect4: "",
      titleSelect5: "",
      panoramaInternacional: "",
      panoramaNacional: "",
      titleSemaforoEconomia1: "",
      colorSemaforoEconomia1: "",
      titleSemaforoEconomia2: "",
      colorSemaforoEconomia2: "",
      titleSemaforoEconomia3: "",
      colorSemaforoEconomia3: "",
      titleSemaforoEconomia4: "",
      colorSemaforoEconomia4: "",
      titleSemaforoEconomia5: "",
      colorSemaforoEconomia5: "",
      titleSemaforoInternacional1: "",
      colorSemaforoInternacional1: "",
      titleSemaforoInternacional2: "",
      colorSemaforoInternacional2: "",
      titleSemaforoInternacional3: "",
      colorSemaforoInternacional3: "",
      titleSemaforoInternacional4: "",
      colorSemaforoInternacional4: "",
      titleSemaforoInternacional5: "",
      colorSemaforoInternacional5: "",
      titleSemaforoLegislativo1: "",
      colorSemaforoLegislativo1: "",
      titleSemaforoLegislativo2: "",
      colorSemaforoLegislativo2: "",
      titleSemaforoLegislativo3: "",
      colorSemaforoLegislativo3: "",
      titleSemaforoLegislativo4: "",
      colorSemaforoLegislativo4: "",
      titleSemaforoLegislativo5: "",
      colorSemaforoLegislativo5: "",
      titleSemaforoSociedad1: "",
      colorSemaforoSociedad1: "",
      titleSemaforoSociedad2: "",
      colorSemaforoSociedad2: "",
      titleSemaforoSociedad3: "",
      colorSemaforoSociedad3: "",
      titleSemaforoSociedad4: "",
      colorSemaforoSociedad4: "",
      titleSemaforoSociedad5: "",
      colorSemaforoSociedad5: "",
      Destacado: "",
    },
  });
  const [porcentajeTrue, setPorcentajeTrue] = useState(true);
  const onSubmit = async (data) => {
    let porcentGeneral =
      parseInt(data.porcentaje1) +
      parseInt(data.porcentaje2) +
      parseInt(data.porcentaje3) +
      parseInt(data.porcentaje4) +
      parseInt(data.porcentaje5);
    if (porcentGeneral === 100) {
      setPorcentajeTrue(true);
      const semaforos = [
        {
          categoria: "Economia",
          luces: [
            {
              articulo: data.titleSemaforoEconomia1.value,
              color: data.colorSemaforoEconomia1,
            },
            {
              articulo: data.titleSemaforoEconomia2.value,
              color: data.colorSemaforoEconomia2,
            },
            {
              articulo: data.titleSemaforoEconomia3.value,
              color: data.colorSemaforoEconomia3,
            },
            {
              articulo: data.titleSemaforoEconomia4.value,
              color: data.colorSemaforoEconomia4,
            },
            {
              articulo: data.titleSemaforoEconomia5.value,
              color: data.colorSemaforoEconomia5,
            },
          ],
        },
        {
          categoria: "Internacional",
          luces: [
            {
              articulo: data.titleSemaforoInternacional1.value,
              color: data.colorSemaforoInternacional1,
            },
            {
              articulo: data.titleSemaforoInternacional2.value,
              color: data.colorSemaforoInternacional2,
            },
            {
              articulo: data.titleSemaforoInternacional3.value,
              color: data.colorSemaforoInternacional3,
            },
            {
              articulo: data.titleSemaforoInternacional4.value,
              color: data.colorSemaforoInternacional4,
            },
            {
              articulo: data.titleSemaforoInternacional5.value,
              color: data.colorSemaforoInternacional5,
            },
          ],
        },
        {
          categoria: "Politica",
          luces: [
            {
              articulo: data.titleSemaforoLegislativo1.value,
              color: data.colorSemaforoLegislativo1,
            },
            {
              articulo: data.titleSemaforoLegislativo2.value,
              color: data.colorSemaforoLegislativo2,
            },
            {
              articulo: data.titleSemaforoLegislativo3.value,
              color: data.colorSemaforoLegislativo3,
            },
            {
              articulo: data.titleSemaforoLegislativo4.value,
              color: data.colorSemaforoLegislativo4,
            },
            {
              articulo: data.titleSemaforoLegislativo5.value,
              color: data.colorSemaforoLegislativo5,
            },
          ],
        },
        {
          categoria: "Sociedad",
          luces: [
            {
              articulo: data.titleSemaforoSociedad1.value,
              color: data.colorSemaforoSociedad1,
            },
            {
              articulo: data.titleSemaforoSociedad2.value,
              color: data.colorSemaforoSociedad2,
            },
            {
              articulo: data.titleSemaforoSociedad3.value,
              color: data.colorSemaforoSociedad3,
            },
            {
              articulo: data.titleSemaforoSociedad4.value,
              color: data.colorSemaforoSociedad4,
            },
            {
              articulo: data.titleSemaforoSociedad5.value,
              color: data.colorSemaforoSociedad5,
            },
          ],
        },
      ];
      const portada = {
        categoria: tipo,
        pie: [
          {
            articulo: data.titleSelect1.value,
            porcentaje: parseInt(data.porcentaje1),
          },
          {
            articulo: data.titleSelect2.value,
            porcentaje: parseInt(data.porcentaje2),
          },
          {
            articulo: data.titleSelect3.value,
            porcentaje: parseInt(data.porcentaje3),
          },
          {
            articulo: data.titleSelect4.value,
            porcentaje: parseInt(data.porcentaje4),
          },
          {
            articulo: data.titleSelect5.value,
            porcentaje: parseInt(data.porcentaje5),
          },
        ],
        panoramaInt: JSON.stringify(
          convertToRaw(internacional.getCurrentContent())
        ),
        panoramaNac: JSON.stringify(convertToRaw(nacional.getCurrentContent())),
        destacado: data.Destacado.value,
      };
      try {
        await crearPortada({ record: portada });
        await crearSemaforos({ records: semaforos });
        alert("Portada guardada");
        window.location.reload();
      } catch (e) {
        alert("Error al guardar portada");
      }
    } else {
      setPorcentajeTrue(false);
    }
  };
  console.log(tipo)
  console.log("hola")
  return (
    <div className="cont">
      <form className="cont-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="breadcrumb">
          <span>Matutino/Portada/{tipo}</span>
        </div>
        <div className="errorReport">
          <span className="error">
            {porcentajeTrue
              ? " "
              : "La suma de de todos los porcentajes debe ser de 100%"}
          </span>
        </div>
        <div className="cont-form-pieGeneral">
          <div className="cont-form-pieGeneral-title">
            <h2>Pie general</h2>
          </div>
          <div className="cont-form-pieGeneral-selects">
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={optionTitles}
                  name="titleSelect1"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>
              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje1"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={optionTitles}
                  name="titleSelect2"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje2"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={optionTitles}
                  name="titleSelect3"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje3"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={optionTitles}
                  name="titleSelect4"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje4"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
            <div className="cont-form-pieGeneral-selects-select">
              <div className="cont-form-pieGeneral-selects-select-button title">
                <Controller
                  as={Select}
                  options={optionTitles}
                  name="titleSelect5"
                  control={control}
                  styles={titleStyles}
                  placeholder="Título del pie"
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="cont-form-pieGeneral-selects-select-button porcentaje">
                <span className="porcent">%</span>
                <input
                  type="number"
                  name="porcentaje5"
                  ref={register({ required: true })}
                  placeholder="Porcentaje"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cont-form-textarea">
          <div className="cont-form-textarea-title">
            <h2>Articulo destacado</h2>
          </div>
          <div className="cont-form-semaforos-row-input">
            <Controller
              as={Select}
              options={optionTitles}
              name="Destacado"
              control={control}
              styles={titleStyles}
              placeholder="Título de texto"
              rules={{
                required: true,
              }}
            />
          </div>
        </div>
        <div className="cont-form-textarea">
          <div className="cont-form-textarea-title">
            <h2>Panorama internacional</h2>
          </div>
          <Editor
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "emoji",
                "remove",
                "history",
              ],
            }}
            editorState={internacional}
            onEditorStateChange={setInternacional}
          />
        </div>
        <div className="cont-form-textarea">
          <div className="cont-form-textarea-title">
            <h2>Panorama nacional</h2>
          </div>
          <Editor
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "emoji",
                "remove",
                "history",
              ],
            }}
            editorState={nacional}
            onEditorStateChange={setNacional}
          />
        </div>
        <div className="cont-form-semaforos">
          <div className="cont-form-semaforos-title">
            <h3>Semáforo Economía</h3>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Economia')}
                name="titleSemaforoEconomia1"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia1"
                  forId="radioRedEconomia1"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia1"
                  forId="radioYellowEconomia1"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia1"
                  forId="radioGreenEconomia1"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Economia')}
                name="titleSemaforoEconomia2"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia2"
                  forId="radioRedEconomia2"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia2"
                  forId="radioYellowEconomia2"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia2"
                  forId="radioGreenEconomia2"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Economia')}
                name="titleSemaforoEconomia3"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia3"
                  forId="radioRedEconomia3"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia3"
                  forId="radioYellowEconomia3"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia3"
                  forId="radioGreenEconomia3"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Economia')}
                name="titleSemaforoEconomia4"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia4"
                  forId="radioRedEconomia4"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia4"
                  forId="radioYellowEconomia4"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia4"
                  forId="radioGreenEconomia4"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Economia')}
                name="titleSemaforoEconomia5"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia5"
                  forId="radioRedEconomia5"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia5"
                  forId="radioYellowEconomia5"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoEconomia5"
                  forId="radioGreenEconomia5"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
        </div>

        <div className="cont-form-semaforos">
          <div className="cont-form-semaforos-title">
            <h3>Semáforo Internacional</h3>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Internacional')}
                name="titleSemaforoInternacional1"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional1"
                  forId="radioRedInternacional1"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional1"
                  forId="radioYellowInternacional1"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional1"
                  forId="radioGreenInternacional1"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Internacional')}
                name="titleSemaforoInternacional2"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional2"
                  forId="radioRedInternacional2"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional2"
                  forId="radioYellowInternacional2"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional2"
                  forId="radioGreenInternacional2"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Internacional')}
                name="titleSemaforoInternacional3"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional3"
                  forId="radioRedInternacional3"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional3"
                  forId="radioYellowInternacional3"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional3"
                  forId="radioGreenInternacional3"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Internacional')}
                name="titleSemaforoInternacional4"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional4"
                  forId="radioRedInternacional4"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional4"
                  forId="radioYellowInternacional4"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional4"
                  forId="radioGreenInternacional4"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Internacional')}
                name="titleSemaforoInternacional5"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional5"
                  forId="radioRedInternacional5"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional5"
                  forId="radioYellowInternacional5"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoInternacional5"
                  forId="radioGreenInternacional5"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-form-semaforos">
          <div className="cont-form-semaforos-title">
            <h3>Semáforo Política</h3>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Politica')}
                name="titleSemaforoLegislativo1"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo1"
                  forId="radioRedLegislativo1"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo1"
                  forId="radioYellowLegislativo1"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo1"
                  forId="radioGreenLegislativo1"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Politica')}
                name="titleSemaforoLegislativo2"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo2"
                  forId="radioRedLegislativo2"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo2"
                  forId="radioYellowLegislativo2"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo2"
                  forId="radioGreenLegislativo2"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Politica')}
                name="titleSemaforoLegislativo3"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo3"
                  forId="radioRedLegislativo3"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo3"
                  forId="radioYellowLegislativo3"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo3"
                  forId="radioGreenLegislativo3"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Politica')}
                name="titleSemaforoLegislativo4"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo4"
                  forId="radioRedLegislativo4"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo4"
                  forId="radioYellowLegislativo4"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo4"
                  forId="radioGreenLegislativo4"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Politica')}
                name="titleSemaforoLegislativo5"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo5"
                  forId="radioRedLegislativo5"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo5"
                  forId="radioYellowLegislativo5"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoLegislativo5"
                  forId="radioGreenLegislativo5"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-form-semaforos">
          <div className="cont-form-semaforos-title">
            <h3>Semáforo Sociedad</h3>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Sociedad')}
                name="titleSemaforoSociedad1"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad1"
                  forId="radioRedSociedad1"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad1"
                  forId="radioYellowSociedad1"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad1"
                  forId="radioGreenSociedad1"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Sociedad')}
                name="titleSemaforoSociedad2"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad2"
                  forId="radioRedSociedad2"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad2"
                  forId="radioYellowSociedad2"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad2"
                  forId="radioGreenSociedad2"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Sociedad')}
                name="titleSemaforoSociedad3"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad3"
                  forId="radioRedSociedad3"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad3"
                  forId="radioYellowSociedad3"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad3"
                  forId="radioGreenSociedad3"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Sociedad')}
                name="titleSemaforoSociedad4"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad4"
                  forId="radioRedSociedad4"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad4"
                  forId="radioYellowSociedad4"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad4"
                  forId="radioGreenSociedad4"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
          <div className="cont-form-semaforos-row">
            <div className="cont-form-semaforos-row-input">
              <Controller
                as={Select}
                options={optionTitles.filter(option => option.category === 'Sociedad')}
                name="titleSemaforoSociedad5"
                control={control}
                styles={titleStyles}
                placeholder="Título de texto"
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className="cont-form-semaforos-row-radio">
              <div className="input-radio">
                <InputRadio
                  color="#FA4443"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad5"
                  forId="radioRedSociedad5"
                  value="Rojo"
                />
                <p>Rojo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#FFE061"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad5"
                  forId="radioYellowSociedad5"
                  value="Amarillo"
                />
                <p>Amarillo</p>
              </div>
              <div className="input-radio">
                <InputRadio
                  color="#5EABA1"
                  register={register({ required: true })}
                  name="colorSemaforoSociedad5"
                  forId="radioGreenSociedad5"
                  value="Verde"
                />
                <p>Verde</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-form-button">
          <ButtonSubmit text="Guardar" />
        </div>
      </form>
    </div>
  );
};
export default MatutinoPortadaGeneral;
