import React, { useContext } from 'react';
import styled from 'styled-components';
import { logout } from '../services/auth';
import { Context } from '../Context/index';

const Button = styled.button`
  height: 30px;
  width: 150px;
  margin: 30px 15px 0 0;
  background-color: #fd7e14;
  border: none;
  color: white;
  font-family: "Oswald";
`;

const LogoutBtn = () => {
  const { logoutCtx } = useContext(Context)

  async function closeSession(){
    await logout()
    logoutCtx()
  }

  return (
    <Button onClick={closeSession}>Cierra sesión</Button>
  )
}

export default LogoutBtn
