import { crearCliente } from "./clientGenerator";
const client = crearCliente();
const { gql } = require("@apollo/client");

const encabezadoDiaDeHoy = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            buscarEncabezados {
              nombre
              link
              titular
              imagen
              indice
              _id
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.buscarEncabezados);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const obtenerEncabezados = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            obtenerEncabezados {
              nombre
              link
              titular
              imagen
              indice
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.obtenerEncabezados);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const crearEncabezado = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation crearEncabezado($record: CreateOneEncabezadoInput!) {
            crearEncabezado(record: $record) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.crearEncabezado);
      })
      .catch((e) => {
        console.log('catch', e)
      });
  });
};

const eliminarEncabezado = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation eliminarEncabezado($id: MongoID!) {
            eliminarEncabezado(_id: $id) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.eliminarEncabezado);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const actualizarEncabezado = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation actualizarEncabezado($record: UpdateByIdEncabezadoInput!) {
            actualizarEncabezado(record: $record) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.actualizarEncabezado);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  encabezadoDiaDeHoy,
  obtenerEncabezados,
  crearEncabezado,
  eliminarEncabezado,
  actualizarEncabezado,
};