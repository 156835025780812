import React, { useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const WYSIWYGEditor = (props) => {
  const [editorState, setEditorState] = React.useState(null);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return props.onChange(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    setEditorState(EditorState.createWithContent(props.initial));// eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <div className="editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "list",
              "textAlign",
              "colorPicker",
              "emoji",
              "remove",
              "history",
            ],
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
