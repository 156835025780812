export const estados = [
  {
    nombre: "Todos",
    clave: "0",
  },
  {
    nombre: "Nayarit",
    clave: "30",
  },
  {
    nombre: "Coahuila",
    clave: "21",
  },
  {
    nombre: "Jalisco",
    clave: "17",
  },
  {
    nombre: "Paises",
    clave: "1",
  },
  {
    nombre: "Guerrero",
    clave: "27",
  },
  {
    nombre: "Oaxaca",
    clave: "32",
  },
  {
    nombre: "Tabasco",
    clave: "38",
  },
  {
    nombre: "Chiapas",
    clave: "23",
  },
  {
    nombre: "Querétaro",
    clave: "34",
  },
  {
    nombre: "Tlaxcala",
    clave: "40",
  },
  {
    nombre: "Baja California",
    clave: "18",
  },
  {
    nombre: "Durango",
    clave: "25",
  },
  {
    nombre: "Morelos",
    clave: "29",
  },
  {
    nombre: "San Luis Potosí",
    clave: "36",
  },
  {
    nombre: "Yucatán",
    clave: "42",
  },
  {
    nombre: "Ciudad de México",
    clave: "9",
  },
  {
    nombre: "Campeche",
    clave: "20",
  },
  {
    nombre: "Guanajuato",
    clave: "26",
  },
  {
    nombre: "Nuevo León",
    clave: "31",
  },
  {
    nombre: "Sonora",
    clave: "37",
  },
  {
    nombre: "Zacatecas",
    clave: "14",
  },
  {
    nombre: "Michoacán",
    clave: "16",
  },
  {
    nombre: "Colima",
    clave: "22",
  },
  {
    nombre: "Sinaloa",
    clave: "15",
  },
  {
    nombre: "Hidalgo",
    clave: "28",
  },
  {
    nombre: "Aguascalientes",
    clave: "13",
  },
  {
    nombre: "Puebla",
    clave: "33",
  },
  {
    nombre: "Tamaulipas",
    clave: "39",
  },
  {
    nombre: "Estado de México",
    clave: "  ",
  },
  {
    nombre: "Chihuahua",
    clave: "24",
  },
  {
    nombre: "Quintana Roo",
    clave: "35",
  },
  {
    nombre: "Veracruz",
    clave: "41",
  },
  {
    nombre: "Baja California Sur",
    clave: "19",
  },
];
