import React from "react";

const TestigosTags = ({ tags, remove }) => {
  return (
    <React.Fragment>
      {(tags ? tags : []).map((art, i) => {
        return (
          <div
            className="btn btn-primary"
            style={{ display: "inline-flex" }}
            key={i}
          >
            <div
              onClick={() => {
                window.open(art.split("~")[1], "_blank");
              }}
            >
              {art.split("~")[0]}
            </div>
            <div
              style={{ paddingLeft: 20 }}
              className="align-content-center text-center"
              onClick={() => {
                remove(i);
              }}
            >
              {" X "}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default TestigosTags;
