import React from "react";
import styled from "styled-components";
import BackButton from "../components/BackButton";
import VerticalText from "../components/VerticalText";
import MatutinoPortadaStructure from "../components/MatutinoPortadaStructure";
const MatutinoPortadaCont = styled.div`
  display: flex;
  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      position: relative;
      display: flex;
      justify-content: center;
      h2 {
        position: absolute;
        font-family: "Oswald";
        font-weight: 400;
        font-size: 72px;
        min-width: 710px;
        text-align: left;
        margin: 0;
      }
    }
  }
  .footer {
    position: fixed;
    width: 100vw;
    height: 70px;
    background: #9d9d9f;
    opacity: 0.2;
    bottom: 0;
  }
`;

const MatutinoPortada = () => {
  return (
    <MatutinoPortadaCont>
      <BackButton urlBack="/matutino" />
      <VerticalText text="matutino" />
      <div className="body">
        <div className="title">
          <h2>Portada</h2>
        </div>
        <MatutinoPortadaStructure
          generalUrl="/matutino/portada/general"
          economiaUrl="/matutino/portada/economia"
          socialUrl="/matutino/portada/social"
          internacionalUrl="/matutino/portada/internacional"
        />
      </div>
      <div className="footer"></div>
    </MatutinoPortadaCont>
  );
};
export default MatutinoPortada;
