import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
const obtenerImagenURL = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query obtenerURLImagen($Imagen: String!) {
            obtenerURLImagen(Imagen: $Imagen)
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.obtenerURLImagen);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { obtenerImagenURL };
