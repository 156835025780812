import React from "react";
import styled from "styled-components";
import { GoCheck } from "react-icons/go";

const InputCheckboxCont = styled.div`
  display: flex;
  .checkboxI {
    height: ${(props) => props.heightR};
    width: ${(props) => props.widthR};
    background: #9d9d9f;
    border: 3px solid #9d9d9f;
    border-radius: 50%;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    display: flex;
    color: #9d9d9f;
    font-size: ${(props) => props.fontSizeR};
    align-items: center;
    justify-content: center;
  }
  .dNone {
    display: none;
    &:checked + label {
      color: ${(props) => props.colorIcon};
      background: ${(props) => props.color};
      border: 3px solid ${(props) => props.borderColor};
    }
  }
`;

const InputCheckbox = ({
  color = "#70A9A1",
  register,
  name,
  forId,
  value,
  icon = true,
  fontSize = "25px",
  colorIcon = "white",
  borderColor = "#70A9A1",
  width = "25px",
  height = "25px",
  onChange,
}) => {
  return (
    <InputCheckboxCont
      color={color}
      fontSizeR={fontSize}
      colorIcon={colorIcon}
      borderColor={borderColor}
      widthR={width}
      heightR={height}
    >
      <input
        type="checkbox"
        name={name}
        id={forId}
        value={value}
        className="dNone"
        ref={register}
        onChange={onChange}
      />
      <label htmlFor={forId} className="checkboxI">
        {" "}
        {icon && <GoCheck />}{" "}
      </label>
    </InputCheckboxCont>
  );
};
export default InputCheckbox;
