import React from "react";
import styled from "styled-components";
import fechaDia from "../utils/fechaDia";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import draftToHtml from "draftjs-to-html";
const fontSize = {
  titleBig: "24px",
  titleRegular: "20px",
  titleSmall: "14px",
  textBig: "16px",
  textRegular: "14px",
  textSmall: "12px",
  buttonRegular: "14px",
  buttonSmall: "12px",
};
const ModalCont = styled.div`
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  .modalCont {
    display: flex;
    flex-direction: column;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &-header {
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      height: 80px;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      display: flex;
      flex-direction: column;
      display: -webkit-flex;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      height: calc(100vh - 80px - env(safe-area-inset-top));
      width: 100%;
      overflow: auto;
      &-img {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        height: auto;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          max-height: 180px;
          object-fit: cover;
        }
      }
      &-date {
        display: flex;
        display: -webkit-box;
        padding: 15px 5%;
        align-items: center;
        .iconDate {
          display: flex;
          margin-right: 10px;
          svg {
            height: 20px;
            width: 20px;
          }
        }
        .spanDate {
          font-family: Crimson Pro;
          font-size: ${fontSize.textRegular};
          line-height: 16px;
        }
      }
      &-title {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        width: 100%;
        padding: 0 5%;
        margin: 10px 0;
        h3 {
          text-align: left;
          font-size: ${fontSize.titleBig};
          font-family: Crimson Pro;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding: 0 5%;
        margin: 20px 0;
        * {
          margin-bottom: 10px;
          text-align: justify;
          font-size: ${(props) =>
            props.appFontSize ? props.appFontSize : "10px"} !important;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalCont {
      width: 100%;
      &-header {
        padding: 0 10%;
      }
      &-body {
        width: 100%;
        &-img {
          img {
            width: 80%;
            height: 100%;
            max-height: 340px;
            margin: 0 10%;
            object-fit: cover;
          }
        }
        &-date {
          width: 100%;
          max-width: 640px;
          margin: 0 auto;
          padding: 15px 0;
        }
        &-title {
          width: 100%;
          max-width: 640px;
          margin: 0 auto;
          padding: 15px 0;
        }
        &-text {
          width: 100%;
          max-width: 640px;
          margin: 0 auto;
          padding: 15px 0;
        }
      }
    }
  }
`;
const ArticuloPie = ({ articulo }) => {
  return (
    <>
      <ModalCont appFontSize={"40px"}>
        <div className="modalCont">
          <div className="modalCont-body">
            <div className="modalCont-body-img">
              <img src={articulo.photoURL} alt="" />
            </div>
            <div className="modalCont-body-date">
              <div className="iconDate">
                <CalendarIcon />
              </div>
              <span className="spanDate"> {fechaDia(new Date())} </span>
            </div>
            <div className="modalCont-body-title">
              <h3> {articulo.titulo} </h3>
            </div>
            <div
              className="modalCont-body-text"
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(articulo.cuerpo)),
              }}
            ></div>
          </div>
        </div>
      </ModalCont>
    </>
  );
};

export default ArticuloPie;
