import React, { useEffect } from "react";
//import { Editor } from "react-draft-wysiwyg";
import WYSIWYGEditor from "../components/WYG";
import InputText from "../components/InputText";
import { convertFromRaw, convertToRaw } from "draft-js";
import { ErrorMessage } from "../components/modules";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { NuevaOpinion } from "./Estilos/OpinionEstilos";
import { actualizarBullet } from "../consumo_apollo_client/clienteBullets";

const MatutinoArticulos = ({ info, cerrar, actualizar }) => {
  const { register, control, handleSubmit, reset, errors: error } = useForm({});
  const { fields, append, remove} = useFieldArray(
    {
      control,
      name: "cuerpo",
      reValidateMode: "onChange",
    }
  );
  useEffect(() => {
    reset({ titulo: info.titulo });
    let data = JSON.parse(info.cuerpo);
    for (let form of data) {
      append({ cuerpo: convertFromRaw(form) });
    }// eslint-disable-next-line
  }, []);

  return (
    <>
      <section>
        <NuevaOpinion>
          <div className="NOCont">
            <div className="NOCont-title">
              <h2>Editar bullets</h2>
            </div>
            <form
              onSubmit={handleSubmit((data) => {
                data._id = info._id;
                data.cuerpo = JSON.stringify(data.cuerpo);
                let dataToSubmit = { record: data };
                actualizarBullet(dataToSubmit)
                  .then(() => {
                    reset();
                    alert("Bullet creado");
                    actualizar();
                    cerrar();
                  })
                  .catch((e) => {
                    console.log(e);
                    alert("Error al crear bullet");
                  });
              })}
            >
              <InputText
                name={"titulo"}
                register={register({ required: true })}
                defaultValue={""}
                placeholder={"Titulo"}
              />
              <ErrorMessage>
                {error["titulo"]?.type === "required" && "Es requerido"}
              </ErrorMessage>
              {fields.map((item, index) => (
                <div key={index}>
                  <Controller
                    as={WYSIWYGEditor}
                    name={`cuerpo.${index}`}
                    control={control}
                    initial={item.cuerpo}
                    defaultValue={convertToRaw(item.cuerpo)}
                    onChange={(e) => {
                      console.log(e);
                    }}
                    rules={{ required: true }}
                  />
                  <ErrorMessage>
                    {error["cuerpo"]?.[index]?.type === "required" &&
                      "Es requerido"}
                  </ErrorMessage>
                  <button type="button" onClick={() => remove(index)}>
                    Borrar sub-bullet
                  </button>
                  {index === fields.length - 1 && (
                    <button
                      type="button"
                      onClick={() => append({ cuerpo: null })}
                    >
                      Añadir otra
                    </button>
                  )}
                </div>
              ))}

              <input type="submit" />
            </form>
          </div>
        </NuevaOpinion>
      </section>
    </>
  );
};
export default MatutinoArticulos;
