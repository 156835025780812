import React from "react";
import styled from "styled-components";
import Button from "../components/Button";

const BoletinesCont = styled.div`
  height: 100vh;
  display: flex;
  width: auto;
  width: 100%;
  .buttonsCont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 860px;
    min-height: 400px;
    margin: auto;
    .buttonCont {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        width: 100%;
      }
    }
    .cont {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

const Boletines = ({
  btnMananera,
  btnMeridiano,
  btnEspecial,
  btnMatutina,
  btnNocturno,
  btnCovid,
}) => {
  return (
    <BoletinesCont>
      <div className="buttonsCont">
        <div className="buttonCont">
          <Button
            text={btnMatutina.text}
            colorBg={btnMatutina.colorBg}
            colorText={btnMatutina.colorText}
            colorBorder={btnMatutina.colorBorder}
            url={`${btnMatutina.url}`}
            width="100%"
            fontSize="72px"
          />
        </div>
        <div className="cont">
          <div className="buttonCont">
            <Button
              text={btnMananera.text}
              colorBg={btnMananera.colorBg}
              colorText={btnMananera.colorText}
              colorBorder={btnMananera.colorBorder}
              url={`${btnMananera.url}`}
              width="270px"
            />
          </div>
          <div className="buttonCont">
            <Button
              text={btnEspecial.text}
              colorBg={btnEspecial.colorBg}
              colorText={btnEspecial.colorText}
              colorBorder={btnEspecial.colorBorder}
              url={`${btnEspecial.url}`}
              width="270px"
            />
          </div>
          <div className="buttonCont">
            <Button
              text={btnCovid.text}
              colorBg={btnCovid.colorBg}
              colorText={btnCovid.colorText}
              colorBorder={btnCovid.colorBorder}
              url={`${btnCovid.url}`}
              width="270px"
            />
          </div>
        </div>
      </div>
    </BoletinesCont>
  );
};
export default Boletines;
