import React, { useState, useRef } from "react";
import { Container, Table, Form, ErrorMessage } from "../components/modules";
import BackButton from "../components/BackButton";
import Breadcrumb from "../components/Breadcrumb";
import ButtonSubmit from "../components/ButtonSubmit";
import { titleStyles } from "../styles/styles";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Modal, Button, Spinner } from "react-bootstrap";
import { estados } from "../InformacionParaArticulos/estados";
import { medios_de_informacion } from "../InformacionParaArticulos/tipos_medios_de_informacion";
import { nombres_de_medios } from "../InformacionParaArticulos/nombres_medios_informacion";
import TestigosTags from "../components/TestigosTags";
import Switch from "react-input-switch";
import {
  categoria,
  clasificacion,
} from "../InformacionParaArticulos/clasificacion";
import RowTable from "../components/modules/RowTable";
import { obtenerArticulos } from "../consumo_apollo_client/clientArticulosAPI";
import {
  crearArticulo,
  obtenerTodosLosArticulosHoy,
} from "../consumo_apollo_client/clienteArticulos";
import TagsInput from "react-tagsinput";
import InputText from "../components/InputText";
import ArticuloPreview from "../components/ArticuloPreview";
import ImgFileDummy from "../assets/img/dummy-picture.jpg";
import {
  ArticulosCreados,
  NuevaSintesis,
  NuevoArticulo,
  InputTagsStyles,
  SintetizarTabla,
} from "./Estilos/ArticulosEstilos";
import FormEditor from "./FormEditor";
import "bootstrap/dist/css/bootstrap.min.css";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import { obtenerImagenURL } from "../consumo_apollo_client/clienteImagen";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const MatutinoArticulos = () => {
  //Obtiene articulos
  const getArticulos = async () => {
    obtenerTodosLosArticulosHoy().then((res) => {
      setArticulos(res);
    });
  };
  //Hooks
  const [articulos, setArticulos] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const inputFileRef = useRef(null);
  const [imgFile, setImgFile] = useState(ImgFileDummy);
  const [testigos, setTestigos] = React.useState();
  const [tableCont, settableCont] = useState([]);
  const [item, setItem] = React.useState();
  const [value, setValue] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const [mostrarTitulos, setMostrarTitulos] = useState(0);
  const [titulos, setTitulos] = useState([]);
  const [nombres_medios, setNombres_medios] = useState([]);
  //Cierra y abre modal
  const handleClose = () => setShow(false);
  const handleClosePrevisualizacion = () => setShowPreview(false);

  //Convierte imagen a base64
  const readURL = () => {
    if (inputFileRef.current.files && inputFileRef.current.files[0]) {
      let reader = new FileReader();
      reader.onload = async (e) => {
        let imagen = await obtenerImagenURL({
          Imagen: e.target.result,
        });
        setImgFile(imagen);
      };
      reader.readAsDataURL(inputFileRef.current.files[0]);
    }
  };

  //Submit de formulario buscador
  const onSubmit = async (data) => {
    let ta = data["tags"];
    let ti = data["tags-inc"];
    let te = data["tags-exc"];
    let query =
      ta.join(",") +
      (ti.length !== 0 ? ":" + ti.join(";") : "") +
      (te.length !== 0 ? ";" + te.join(";") : "");
    setLoading(true);
    let nomnbres_medio = [];
    let todos = false;
    for (let m of data.nombreMedio) {
      if (m.clave === 0) {
        todos = true;
        break;
      }
    }
    if (todos) {
      for (let m of nombres_medios) {
        nomnbres_medio.push(parseInt(m.clave));
      }
      nomnbres_medio.shift();
    } else {
      for (let m of data.nombreMedio) {
        nomnbres_medio.push(parseInt(m.clave));
      }
    }
    data = {
      ambito: [parseInt(data.ambito.clave)],
      palabras: query,
      fechaInicio: data.fechaInicial.replace("T", " ") + ":00",
      fechaFinal: data.fechaFinal.replace("T", " ") + ":00",
      nombre_medio: nomnbres_medio,
      tipo_medio: parseInt(data.medio.clave),
      clasificacion: "0",
      estado: parseInt(data.estado.clave),
    };
    try {
      settableCont([]);
      let info = await obtenerArticulos(data);
      settableCont(info.data.buscarArticulos ? info.data.buscarArticulos : []);
      setLoading(false);
    } catch (e) {
      alert("Error al buscar articulos");
    }
    setLoading(false);
  };

  //Testigos tags
  const removeTestigo = (index) => {
    let testigotemp = [...testigos];
    testigotemp = testigotemp.splice(index, 1);
    setTestigos(testigotemp);
  };

  const fetchMedios = async () => {
    nombres_de_medios()
      .then((res) => {
        setNombres_medios(res);
      })
      .catch(() => {
        setNombres_medios([]);
      });
  };

  //Fetch articulos al iniciar
  React.useEffect(() => {
    fetchMedios();
    getArticulos();
  }, []);

  //Agregar informacion del buscador a el creador de articulos
  const onSubmitTable = (data) => {
    let texto = "";
    let testigostext = "";
    let titulos = [];
    for (let id of data.sintetizar) {
      titulos.push(tableCont[parseInt(id)].titulo);
      texto = texto + " " + tableCont[parseInt(id)].texto;
      testigostext =
        testigostext +
        "," +
        tableCont[parseInt(id)].nombre_medio +
        "~" +
        tableCont[parseInt(id)].testigo;
    }
    setTitulos(titulos);
    setTestigos(testigostext.substr(1, testigostext.length).split(","));
    setValue(EditorState.createWithContent(ContentState.createFromText(texto)));
  };

  //Agrega el articulo creado
  const onSubmitNuevaSintesis = (data) => {
    if (!cargando) {
      setMostrarTitulos(false);
      setTitulos([]);
      let record = {
        record: {
          categoria: data.categoria.clave,
          cuerpo: JSON.stringify(convertToRaw(value.getCurrentContent())),
          photoURL: imgFile,
          palabrasClave: data.palabrasClave.split(","),
          subtitulo: data.subtitulo,
          testigos: testigos,
          titulo: data.titulo,
        },
      };
      console.log(JSON.stringify(record.record));
      setCargando(true);
      crearArticulo(record)
        .then(() => {
          getArticulos();
          setValue(null);
          setImgFile(ImgFileDummy);
          setTestigos("");
          setTitulos([]);
          reset({
            categoria: "",
            palabrasClave: "",
            subtitulo: "",
            testigos: "",
            titulo: "",
          });
          setCargando(false);
          alert("Artículo creado");
        })
        .catch((e) => {
          setCargando(false);
          alert("Error al crear artículo");
        });
    }
  };

  //Agrega el articulo creado
  const onSubmitPrevisualizar = (data) => {
    setMostrarTitulos(false);
    setTitulos([]);
    let record = {
      record: {
        categoria: data.categoria.nombre,
        cuerpo: JSON.stringify(convertToRaw(value.getCurrentContent())),
        photoURL: imgFile,
        palabrasClave: data.palabrasClave.split(","),
        subtitulo: data.subtitulo,
        testigos: testigos,
        titulo: data.titulo,
      },
    };
    setItem(record.record);
    setShowPreview(true);
  };

  //Validador busqueda
  const {
    register: registerNA,
    control: controlNA,
    handleSubmit: handleSubmitNA,
    errors: errorsNA,
  } = useForm({
    defaultValues: {
      "tags-inc": [],
      "tags-exc": [],
      ambito: "",
      medio: "",
      estado: "",
      nombreMedio: "",
    },
  });
  //Tabla
  const {
    register: registerS,
    handleSubmit: handleSubmitS,
  } = useForm({
    defaultValues: {
      nombreMedio: "",
      cuerpo: null,
    },
  });
  //Articulo
  const {
    register: registerNS,
    control: controlNS,
    reset,
    handleSubmit: handleSubmitNS,
    errors: errorsNS,
  } = useForm({
    defaultValues: {
      categoria: "Agenda",
      nombreMedio: "",
      cuerpo: null,
    },
  });

  //Componente
  return (
    <>
      <BackButton urlBack="/matutino" position={true} />
      <Container>
        <Breadcrumb text="Matutino/Artículos" />
        <ArticulosCreados>
          <div className="title">
            <h3> Artículos creados </h3>
          </div>
          <div className="text">
            {articulos.length === 0 ? (
              <p> No hay ningún artículo creado todavía.. </p>
            ) : (
                <div className="container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Titulo</th>
                        <th>Autor</th>
                        <th>Palabras clave</th>
                        <th>Visualizar</th>
                        <th>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {articulos.map((elem, i) => {
                        return (
                          <tr key={i}>
                            <td>{elem.titulo}</td>
                            <td>{elem.categoria}</td>
                            <td>{elem.palabrasClave}</td>
                            <td
                              style={{ color: "#235789" }}
                              onClick={() => {
                                setShowPreview(true);
                                setItem(elem);
                              }}
                            >
                              <p>Ver</p>
                            </td>
                            <td
                              style={{ color: "#235789" }}
                              onClick={() => {
                                setShow(true);
                                setItem(elem);
                              }}
                            >
                              <p>Editar</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </ArticulosCreados>
        <Container margin="0" flexDirection="row">
          <NuevoArticulo>
            <div className="title">
              <h3>Filtros</h3>
            </div>
            <form
              onError={() => {
                console.log(errorsNA);
              }}
              key={1}
              onSubmit={handleSubmitNA(onSubmit)}
            >
              <div className="dateTime">
                Fecha inicial:
                <div className="dateTime-row">
                  <div className="dateTime-row-input">
                    <input
                      type="datetime-local"
                      control={controlNA}
                      name="fechaInicial"
                      ref={registerNA({ required: true })}
                    />
                  </div>
                </div>
                <ErrorMessage>
                  {errorsNA.fechaInicial?.type === "required" && "Es requerido"}
                </ErrorMessage>
                Fecha final:
                <div className="dateTime-row">
                  <div className="dateTime-row-input">
                    <input
                      type="datetime-local"
                      control={controlNA}
                      name="fechaFinal"
                      ref={registerNA({ required: true })}
                    />
                  </div>
                </div>
                <ErrorMessage>
                  {errorsNA.fechaFinal?.type === "required" && "Es requerido"}
                </ErrorMessage>
              </div>
              <div className="select">
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={estados}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="estado"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un estado"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.estado?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={medios_de_informacion}
                    getOptionLabel={(options) => options.descripcion}
                    getOptionValue={(options) => options.clave}
                    name="medio"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un medio"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.medio?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={nombres_medios}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="nombreMedio"
                    isMulti
                    isClearable={true}
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona un nombre de medio"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.nombreMedio?.type === "required" &&
                      "Es requerido"}
                  </ErrorMessage>
                </div>
                <div className="select-row h-auto">
                  <Controller
                    as={Select}
                    options={clasificacion}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="ambito"
                    control={controlNA}
                    styles={titleStyles}
                    placeholder="Selecciona una clasificación"
                    rules={{
                      required: true,
                    }}
                  />
                  <ErrorMessage>
                    {errorsNA.ambito?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                  <div className="select-row h-auto">
                    Palabras clave
                    <InputTagsStyles>
                      <Controller
                        name="tags"
                        control={controlNA}
                        rules={{
                          validate: (data) => {
                            if (data.length === 0) return false;
                            return true;
                          },
                        }}
                        render={({ onChange, onBlur, value, ref, name }) => (
                          <TagsInput
                            onChange={onChange}
                            value={value}
                            inputProps={{
                              placeholder: "Ingresa las palabras clave",
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </InputTagsStyles>
                  </div>
                  <ErrorMessage>
                    {errorsNA["tags"]?.type === "validate" && "Es requerido"}
                  </ErrorMessage>
                  Filtros avanzados
                  <div className="select-row h-auto">
                    Incluir en la búsqueda
                    <InputTagsStyles>
                      <Controller
                        name="tags-inc"
                        control={controlNA}
                        rules={{
                          validate: (data) => {
                            return true;
                          },
                        }}
                        render={({ onChange, onBlur, value, ref, name }) => (
                          <TagsInput
                            onChange={onChange}
                            value={value}
                            inputProps={{
                              placeholder: "Ingresa palabras de contexto",
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </InputTagsStyles>
                  </div>
                  <ErrorMessage>
                    {errorsNA["tags-inc"]?.type === "validate" &&
                      "Es requerido"}
                  </ErrorMessage>
                  <div className="select-row h-auto">
                    Excluir en la búsqueda
                    <InputTagsStyles>
                      <Controller
                        name="tags-exc"
                        control={controlNA}
                        render={({ onChange, onBlur, value, ref, name }) => (
                          <TagsInput
                            onChange={onChange}
                            value={value}
                            inputProps={{
                              placeholder:
                                "Ingresa los temas que deseas excluir",
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </InputTagsStyles>
                  </div>
                  <ErrorMessage>
                    {errorsNA["tags-exc"]?.type === "validate" &&
                      "Es requerido"}
                  </ErrorMessage>
                </div>
              </div>
              <ButtonSubmit text="Buscar" bg="#2F2F2F" />
            </form>
          </NuevoArticulo>
          {tableCont.length > 0 ? (
            <SintetizarTabla>
              <div className="tablaHeader">
                <div className="tablaHeader-row">
                  <div className="tablaHeader-row-check"></div>
                  <div className="tablaHeader-row-titulo">
                    <p>Título</p>
                  </div>
                  <div className="tablaHeader-row-medio">
                    <p>Medio</p>
                  </div>
                  <div className="tablaHeader-row-tipo">
                    <p>Tipo</p>
                  </div>
                </div>
              </div>
              <Form onSubmit={handleSubmitS(onSubmitTable)}>
                <Table>
                  {tableCont.map((data, i) => (
                    <RowTable
                      key={i}
                      name={"sintetizar"}
                      value={i}
                      forId={`checkTG${i}`}
                      titulo={data.titulo}
                      medio={data.nombre_medio}
                      tipo={data.tipo_medio}
                      register={registerS}
                    />
                  ))}
                </Table>
                <div className="button">
                  <ButtonSubmit text="Sintetizar" bg="#FA4443" />
                </div>
              </Form>
            </SintetizarTabla>
          ) : (
              <>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <>
                    {<p>No hay resultados para mostrar</p>}
                    {loading && <Spinner animation="border" />}
                  </>
                </div>
              </>
            )}
        </Container>
        <Container margin="0 0 80px 0">
          <NuevaSintesis imgFile={imgFile}>
            <div className="NSCont">
              <div className="NSCont-title">
                <h2>Nueva Síntesis</h2>
              </div>
              <div className="NSCont-row">
                <div className="NSCont-row-input w-65">
                  <InputText
                    name="titulo"
                    placeholder="Titulo"
                    register={registerNS({ required: true })}
                  />
                </div>
                <div className="NSCont-row-input w-30">
                  <Controller
                    as={Select}
                    options={categoria}
                    getOptionLabel={(options) => options.nombre}
                    getOptionValue={(options) => options.clave}
                    name="categoria"
                    control={controlNS}
                    styles={titleStyles}
                    placeholder="Sección de la nota"
                    rules={{
                      required: true,
                    }}
                  />
                </div>
              </div>
              <ErrorMessage>
                {errorsNS.titulo?.type === "required" && "Es requerido"}
              </ErrorMessage>
              <div className="NSCont-row">
                <div className="cont">
                  <div className="input">
                    <InputText
                      name="subtitulo"
                      placeholder="Subtítulo"
                      register={registerNS({ required: true })}
                    />
                  </div>
                  <ErrorMessage>
                    {errorsNS.titulo?.type === "required" && "Es requerido"}
                  </ErrorMessage>
                  <div className="input">
                    Tags:
                    <TestigosTags tags={testigos} remove={removeTestigo} />
                  </div>
                </div>
                <div className="NSCont-row-textarea">
                  <textarea
                    name="palabrasClave"
                    cols="30"
                    rows="4"
                    placeholder="Palabras clave"
                    ref={registerNS({ required: true })}
                  ></textarea>
                </div>
              </div>
              <br />
              <div>
                Títulos
                <Switch value={mostrarTitulos} onChange={setMostrarTitulos} />
                {mostrarTitulos === 1 &&
                  titulos.map((item) => {
                    return <InputText value={item} placeholder="Titulo" />;
                  })}
              </div>
              <br />
              <div className="cont-form-textarea">
                <div className="cont-form-textarea-contTexarea">
                  <Controller
                    as={Editor}
                    editorState={value}
                    onEditorStateChange={setValue}
                    name="cuerpo"
                    control={controlNS}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
                <ErrorMessage>
                  {errorsNS.cuerpo?.type === "required" && "Es requerido"}
                </ErrorMessage>
              </div>
              <div className="NSCont-row"></div>
              <div className="NSCont-row">
                <div className="NSCont-row-fotoCover">
                  <div className="NSCont-row-fotoCover-contButton">
                    <div className="NSCont-row-fotoCover-contButton-title">
                      <h2>Foto Cover</h2>
                    </div>
                    <div className="NSCont-row-fotoCover-contButton-button">
                      <span className="upload">Subir archivo</span>
                      <input
                        ref={(e) => {
                          registerNS(e, { required: true });
                          inputFileRef.current = e;
                        }}
                        name="photoURL"
                        id="test1"
                        type="file"
                        onChange={readURL}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                  <div className="NSCont-row-fotoCover-img"></div>
                </div>
              </div>
              <ErrorMessage>
                {errorsNS.photoURL?.type === "required" && "Es requerido"}
              </ErrorMessage>
              <ButtonSubmit
                text="Previsualizar"
                onClick={handleSubmitNS(onSubmitPrevisualizar)}
              />
              <br />
              <ButtonSubmit
                text="Guardar"
                onClick={handleSubmitNS(onSubmitNuevaSintesis)}
              />
            </div>
          </NuevaSintesis>
        </Container>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Articulo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {item !== undefined && (
              <FormEditor
                actualizar={getArticulos}
                cerrar={handleClose}
                info={item}
              ></FormEditor>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button letiant="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          show={showPreview}
          onHide={handleClosePrevisualizacion}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Previsualizacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>{item && <ArticuloPreview articulo={item} />}</Modal.Body>
          <Modal.Footer>
            <Button letiant="primary" onClick={handleClosePrevisualizacion}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};
export default MatutinoArticulos;
