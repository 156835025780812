import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Form } from "../components/modules";
import { useForm } from "react-hook-form";
import { login } from '../services/auth';
import { Context } from '../Context/index';
import { ReactComponent as MDMLogo } from '../assets/logos/Completo.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/eye_icon.svg'

const FormCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: 'Open Sans';

  .logo{
    width: 500px;
    margin: 8% auto 50px;

  }

  h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 4%;
  }

  label,
  input {
    font-size: 14px;
    width: 385px;
    margin: auto;
  }

  input {
    border: 0.5px solid #868585;
    border-radius: 2px;
    height: 46px;
    margin: 8px auto 20px;
    padding: 0 8px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  div input {
    border-right: none;
    border-radius: 2px 0 0 2px;
    margin: 0;
    width: 350px;
  }

  .icon {
    border: 0.5px solid #868585;
    border-left: none;
    height: 46px;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    width: 30px;
    padding-right: 5px; 
  }

  a {
    color: #235789;
    font-weight: 600;
    font-size: 13px;
    width: 385px;
    margin: auto;
    text-align: right;
  }

  button {
    width: 184px;
    height: 32px;
    margin: 6% auto 0;
    background-color: #7190AE;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #DB2C2B;
  text-transform: uppercase;
  width: 385px;
  margin: auto;
`;



const Login = ({ history }) => {
  const { loginCtx } = useContext(Context)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const { register, handleSubmit, errors } = useForm();
  const [inputType, setInputType] = useState("password")
 
  const onSubmit = async data => {
    try {
      const user = await login(data)
      setErrorEmail(null)
      setErrorPassword(null)
      loginCtx(user)
      history.push("/")
    }
    catch (error){
      console.error(error.response.data.failureDetails.message)
      if (error.response.data.failureDetails.message === "Contraseña incorrecta"){
        setErrorPassword(error.response.data.failureDetails.message)
        setErrorEmail(null)
      } else {
        setErrorEmail(error.response.data.failureDetails.message)
        setErrorPassword(null)
      }
    }
  }


  const viewPassword = () => {
    if (inputType === "password") {
      setInputType("text")
    } else {
      setInputType("password")
    }
  }

  return (
      <FormCont>
        <MDMLogo className="logo"/>
        <h2>Bienvenidos al editor de El medio de medios, por favor ingresa tus datos para acceder.</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">Correo</label>
          <input ref={register({ required: true })} type="text" id="email" name="email" placeholder="Ingresa aquí tu correo electrónico"/>
          {errorEmail && <ErrorMessage>{errorEmail}</ErrorMessage>}
          <ErrorMessage>{errors["email"]?.type === "required" && "Proporciona un email"}</ErrorMessage>
          <label htmlFor="password" >Contraseña</label>
          <div>
            <input ref={register({ required: true })} type={inputType} id="password" name="password" placeholder="Ingresa aquí tu contraseña"/>
            <EyeIcon className="icon" onClick={viewPassword}/>
          </div>
          {errorPassword && <ErrorMessage>{errorPassword}</ErrorMessage>}
          <ErrorMessage>{errors.password?.type === "required" && "Proporciona una contraseña"}</ErrorMessage>
          <a href="/">Olvidé mi contraseña</a>
          <button type="submit">Ingresar</button>
        </Form>
      </FormCont>
  );
};
export default Login;