import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form } from "../components/modules";
import { useForm } from "react-hook-form";
import { signup } from '../services/auth'
import { allUsers, removeUser } from '../services/users'
import { ReactComponent as MDMLogo } from '../assets/logos/Completo.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/eye_icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/icons/close_icon.svg'


const FormCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: 'Open Sans';

  .logo{
    width: 500px;
    margin: 70px auto 30px;
  }

  h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 70px;
  }

  label,
  input {
    font-size: 14px;
    width: 385px;
    margin: auto;
  }

  input {
    border: 0.5px solid #868585;
    border-radius: 2px;
    height: 46px;
    margin: 8px auto 20px;
    padding: 0 8px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  div input {
    border-right: none;
    border-radius: 2px 0 0 2px;
    margin: 0;
    width: 350px;
  }

  .icon {
    border: 0.5px solid #868585;
    border-left: none;
    height: 46px;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    width: 30px;
    padding-right: 5px; 
  }

  button {
    width: 184px;
    height: 32px;
    margin: 70px auto 0;
    background-color: #7190AE;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #DB2C2B;
  text-transform: uppercase;
  width: 385px;
  margin: auto;
`;

const List = styled.div`
  font: 16px 'Open Sans';
  width: 385px;
  margin: 35px auto;
  flex-direction: column;

  h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 4%;
  }
  
  ul {
    list-style: none;
    width: 100%;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }

  .delete{
    height: 10px;
    width: 35px;
    cursor: pointer;
    padding-left: 3%;
  }
`;

const Signup = () => {
  const [error, setError] = useState(null)
  const { register, handleSubmit, errors } = useForm();
  const [inputType, setInputType] = useState("password")
  const [users, setUsers] = useState(null)
  const [change, setChange] = useState(false)
  const [messageNewUser, setmessageNewUser] = useState(false)

 
  const onSubmit = async data => {
    try {
      await signup(data)
      setmessageNewUser(true)
      setChange(true)
    }
    catch (error){
      console.error(error.response.data.message)
      setError(error.response.data.message)
      
    }
  }

  const viewPassword = () => {
    if (inputType === "password") {
      setInputType("text")
    } else {
      setInputType("password")
    }
  }

  const deleteUser = async userId => {
    try { 
      await removeUser(userId)
      setChange(true)
    } catch (error) {
      console.error(error)
    }
  } 

  useEffect(() => {
    async function getAllUser(){
      const users = await allUsers()
      setUsers(users)
    }
    getAllUser()
    setChange(false)

  }, [change])

  return (
      <FormCont>
        <MDMLogo className="logo"/>
        <h2>Crear una nueva cuenta</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">Correo</label>
          <input ref={register({ required: true })} type="text" id="email" name="email" placeholder="Ingresa aquí tu correo electrónico"/>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ErrorMessage>{errors["email"]?.type === "required" && "Proporciona un email"}</ErrorMessage>
          <label htmlFor="password" >Contraseña</label>
          <div>
            <input ref={register({ required: true })} type={inputType} id="password" name="password" placeholder="Ingresa aquí tu contraseña"/>
            <EyeIcon className="icon" onClick={viewPassword}/>
          </div>
          <ErrorMessage>{errors.password?.type === "required" && "Proporciona una contraseña"}</ErrorMessage>
          <button type="submit">Crear cuenta</button>
        </Form>
        <List>
          <h3>Cuentas creadas</h3>
          {messageNewUser && <p>El usuario ha sido creado exitosamente.</p>}
          <ul>
            {users?.map(user => (
                <li key={user._id}>{user.email} {user?.email !== `${process.env.REACT_APP_ADMIN}` && <DeleteIcon className="delete" onClick={()=> deleteUser(user._id)}/>}</li>
            ))}
          </ul>
        </List>
      </FormCont>
  );
};
export default Signup;