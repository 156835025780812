import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");

const client = crearCliente();
/**
 * Solo llama el metodo y resuelve con promesa o async
 * datos:
 * titulo: String
 * subtitulo: String
 * cuerpo: String
 * autor: String
 * piePorciento: String
 * pieColor: String
 * categoria: String
 * semaforo: String
 * fuentes: [String]
 * @return ID del articulo
 */

const crearArticulo = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation articuloCreateOne($record: CreateOneArticuloInput!) {
            articuloCreateOne(record: $record) {
              recordId
              record {
                titulo
                subtitulo
                cuerpo
                autor
                categoria
                photoURL
                testigos
                palabrasClave
                _id
                updatedAt
                createdAt
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.articuloCreateOne);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const obtenerArticuloporId = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query articuloById($id: MongoID!) {
            articuloById(_id: $id) {
              titulo
              subtitulo
              cuerpo
              autor
              piePorciento
              pieColor
              categoria
              semaforo
              fuentes
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.articuloById);
      });
  });
};

const obtenerArticulos = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query articuloMany {
            articuloMany {
              titulo
              subtitulo
              cuerpo
              autor
              piePorciento
              pieColor
              categoria
              semaforo
              fuentes
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.articuloMany);
      });
  });
};

const ARTICULO_POR_CATEGORIA = gql`
        query articuloDiadeHoy($tipo: String) {
          articuloDiadeHoy(tipo: $tipo) {
            titulo
            subtitulo
            cuerpo
            autor
            categoria
            photoURL
            palabrasClave
            testigos
            _id
            updatedAt
            createdAt
          }
        }
      `

const eliminarArticulo = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation articuloRemoveById($id: MongoID!) {
            articuloRemoveById(_id: $id) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.articuloRemoveById);
      });
  });
};

const actualizarArticulo = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation articuloUpdateById($record: UpdateByIdArticuloInput!) {
            articuloUpdateById(record: $record) {
              recordId
              record {
                titulo
                subtitulo
                cuerpo
                autor
                categoria
                photoURL
                testigos
                _id
                updatedAt
                createdAt
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.articuloUpdateById);
      });
  });
};

const obtenerTodosLosArticulosHoy = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            articuloTodosDiadeHoy {
              titulo
              subtitulo
              cuerpo
              autor
              categoria
              photoURL
              palabrasClave
              testigos
              _id
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.articuloTodosDiadeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  crearArticulo,
  obtenerArticuloporId,
  ARTICULO_POR_CATEGORIA,
  obtenerArticulos,
  eliminarArticulo,
  actualizarArticulo,
  obtenerTodosLosArticulosHoy,
};
