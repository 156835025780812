import React from "react";
import styled from "styled-components";
import fechaDia from "../utils/fechaDia";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import draftToHtml from "draftjs-to-html";

const fontSize = {
  titleBig: "24px",
  titleRegular: "20px",
  titleSmall: "14px",
  textBig: "16px",
  textRegular: "14px",
  textSmall: "12px",
  buttonRegular: "14px",
  buttonSmall: "12px",
};
const ModalCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .modalCont {
    display: -webkit-box;
    display: -ms-flexbox;
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 80px - env(safe-area-inset-top));
      width: 100%;
      max-width: 580px;
      margin: auto;
      overflow: auto;
      &-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 15px 5%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .date {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          .iconDate {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: 10px;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          .spanDate {
            font-family: Crimson Pro;
            font-size: ${fontSize.textRegular};
            line-height: 16px;
          }
        }
        .by {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          p {
            font-family: Crimson Pro;
            font-style: italic;
            font-weight: 600;
          }
        }
      }
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding: 0 5%;
        margin: 10px 0;
        h3 {
          font-size: 1.17em;
          text-align: left;
          font-size: ${fontSize.titleBig};
          font-family: Crimson Pro;
          font-weight: bold;
        }
      }
      &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0 5%;
        margin: 20px 0;
        * {
          margin-bottom: 10px;
          text-align: justify;
          font-size: ${(props) =>
            props.appFontSize ? props.appFontSize : "10px"} !important;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

const ArticuloOpinion = ({ opinion }) => {
  return (
    <>
      <ModalCont appFontSize={undefined}>
        <div className="modalCont">
          <div className="modalCont-body">
            <div className="modalCont-body-title">
              <h3> {opinion.titulo} </h3>
            </div>
            <div className="modalCont-body-date">
              <div className="date">
                <CalendarIcon className="iconDate" />
                <span className="spanDate"> {fechaDia(new Date())} </span>
              </div>
              <div className="by">
                <p> Por: {opinion.autor} </p>
              </div>
            </div>
            <div
              className="modalCont-body-text"
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(opinion.cuerpo)),
              }}
            ></div>
          </div>
        </div>
      </ModalCont>
    </>
  );
};

export default ArticuloOpinion;
