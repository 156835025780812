import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import { ArticulosCreados } from "./Estilos/OpinionEstilos";
import {
  actualizarBullet,
  obtenerBullets,
} from "../consumo_apollo_client/clienteBullets";

const MatutinoArticulos = () => {
  const [opiniones, setOpiniones] = useState([]);
  useEffect(() => {
    cargarBullets();
  }, []);

  const switchInfo = (indexA, indexB) => {
    let temp = opiniones[indexA];
    opiniones[indexA] = opiniones[indexB];
    opiniones[indexB] = temp;
    let idtemp = opiniones[indexA]._id;
    opiniones[indexA]._id = opiniones[indexB]._id;
    opiniones[indexB]._id = idtemp;
    temp = [...opiniones];
    setOpiniones(temp);
  };

  const aplicarCambios = async () => {
    try {
      for (let op of opiniones) {
        delete op.__typename;
        await actualizarBullet({ record: op });
      }
      alert("Se han guardado los nuevo lugares");
      cargarBullets();
    } catch (e) {
      console.log(e);
      alert("Error al actualizar posiciones");
    }
  };
  const cargarBullets = () => {
    obtenerBullets()
      .then((res) => {
        setOpiniones(res);
      })
      .catch((err) => {
        setOpiniones([]);
      });
  };
  return (
    <>
      <BackButton urlBack="/mañanera/" position={true} />
      <section>
        <ArticulosCreados>
          <div className="title">
            <h3> Bullets creadas</h3>
          </div>
          <div className="text">
            {opiniones.length === 0 ? (
              <p> No hay ninguna bullet creada todavía.. </p>
            ) : (
              <div className="container">
                <table className="table">
                  <tbody>
                    {opiniones.map((elem, i) => {
                      return (
                        <tr key={i}>
                          <td>{elem.titulo}</td>
                          <td
                            onClick={() => {
                              if (i - 1 > -1) {
                                switchInfo(i, i - 1);
                              } else {
                                alert("No hay bullet arriba");
                              }
                            }}
                            style={{ color: "#235789" }}
                          >
                            <a href>Arriba</a>
                          </td>
                          <td
                            onClick={() => {
                              if (i + 1 < opiniones.length) {
                                switchInfo(i, i + 1);
                              } else {
                                alert("No hay bullet abajo");
                              }
                            }}
                            style={{ color: "#235789" }}
                          >
                            <a href>Abajo</a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <button
            onClick={() => {
              aplicarCambios();
            }}
          >
            Publicar
          </button>
        </ArticulosCreados>
      </section>
    </>
  );
};
export default MatutinoArticulos;
