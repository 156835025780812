import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
/**
 * Solo llama el metodo y resuelve con promesa o async
 * @param
 * datos: {
 *  panoramaNac: String
    panoramaInt: String
    photoUrl: String
    resumen: String
    categoria: String
 * }
 * @return {Array} Arreglo de opiniones
 */

const crearPortada = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation portadaCreateOne($record: CreateOnePortadaInput!) {
            portadaCreateOne(record: $record) {
              recordId
              record {
                panoramaNac
                panoramaInt
                destacado
                categoria
                pie {
                  articulo
                  porcentaje
                }
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.portadaCreateOne);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Solo llama el metodo y resuelve con promesa o async
 * @return Arreglo de todas las portadas
 **/
const obtenerPortadas = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            portadaMany {
              _id
              panoramaNac
              panoramaInt
              photoUrl
              resumen
              categoria
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.portadaMany);
      });
  });
};

/**
 * Solo llama el metodo y resuelve con promesa o async
 * @param datos
 * datos={id:"id de la portada"}
 * @return Todos los campos de la portada
 **/

const obtenerPortadaporId = (data) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query portadaById($id: MongoID!) {
            portadaById(_id: $id) {
              _id
              panoramaNac
              panoramaInt
              photoUrl
              resumen
              categoria
            }
          }
        `,
        variables: data,
      })
      .then((result) => {
        resolve(result.data.portadaById);
      });
  });
};

/**
 * Solo llama el metodo y resuelve con promesa o async
 * data:{id:"ID de la portada",... campos a actualizar clave:valor
 * @return ID de la portada modificada
 */

const actualizarPortada = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation portadaUpdateOne(
            $id: MongoID!
            $panoramaNac: String
            $panoramaInt: String
            $photoUrl: String
            $resumen: String
            $categoria: String
          ) {
            portadaUpdateOne(
              filter: { _id: $id }
              record: {
                panoramaNac: $panoramaNac
                panoramaInt: $panoramaInt
                photoUrl: $photoUrl
                resumen: $resumen
                categoria: $categoria
              }
            ) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data);
      });
  });
};

/*
 * Eliminar portada
 * Solo llama el metodo y resuelve con promesa o async
 * data:{id:"ID de la portada"}
 * @return ID de la portada modificada
 */

const eliminarPortada = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation portadaRemoveById($id: MongoID!) {
            portadaRemoveById(_id: $id) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.portadaRemoveById);
      });
  });
};

const obtenerPortadaPorCategoria = (categoria) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
              portadaDiaDeHoy(tipo: "${categoria}") {
              _id
              panoramaNac
              panoramaInt
              photoUrl
              resumen
              categoria
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.portadaDiaDeHoy);
      });
  });
};
export {
  obtenerPortadas,
  obtenerPortadaporId,
  crearPortada,
  eliminarPortada,
  actualizarPortada,
  obtenerPortadaPorCategoria,
};
