const meses = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};
const dias = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const DiaDeHoy = () => {
  let fecha = new Date();
  let dia = fecha.getDay();
  let diames = fecha.getUTCDate();
  let mes = fecha.getMonth();
  let años = fecha.getFullYear();
  return dias[dia] + " " + diames + "/" + meses[mes] + "/" + años;
};

export default DiaDeHoy;
