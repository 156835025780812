import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();

const obtenerBullets = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            bulletDiadeHoy {
              titulo
              cuerpo
              _id
              autor
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.bulletDiadeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const crearBullet = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation bulletCreateOne($record: CreateOneBulletInput!) {
            bulletCreateOne(record: $record) {
              recordId
              record {
                titulo
                cuerpo
                _id
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.bulletCreateOne);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const actualizarBullet = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation bulletUpdateById($record: UpdateByIdBulletInput!) {
            bulletUpdateById(record: $record) {
              recordId
              record {
                titulo
                cuerpo
                _id
              }
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.bulletUpdateById);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const crearBullets = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation bulletCreateMany($records: [CreateManyBulletInput!]!) {
            bulletCreateMany(records: $records) {
              recordIds
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.bulletCreateMany);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const EliminarBullet = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation bulletRemoveById($id: MongoID!) {
            bulletRemoveById(_id: $id) {
              recordId
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.bulletRemoveById);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  obtenerBullets,
  crearBullet,
  actualizarBullet,
  crearBullets,
  EliminarBullet,
};
