export const medios_de_informacion = [
  {
    descripcion: "Todos",
    clave: "0",
  },
  {
    descripcion: "Electrónicos",
    clave: "2",
  },
  {
    descripcion: "Impresos",
    clave: "1",
  },
];
