import styled from "styled-components";
import chroma from "chroma-js";

export const colourStyles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #fff",
    borderBottom: "1px solid black",
    borderRadius: "none",
    fontFamily: "Oswald",
    color: "#000000",
    cursor: "pointer",
    boxShadow: "none",
    ":hover": {
      border: "1px solid #fff",
      borderBottom: "1px solid black",
      borderRadius: "none",
    },
    ":focus": {
      outline: "none",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    opacity: 0,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    fontFamily: "Oswald",
    textAlign: "left",
    zIndex: 2,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  // singleValue: (styles) => ({
  //   ...styles,
  //   margin: "0px",
  // }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "2px 0px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "black",
    fontSize: "20px",
    content: '" "',
    display: "flex",
    ":hover": {
      color: "blue",
    },
    ":before": {
      content: '" "',
      position: "absolute",
      border: "6px solid white",
      borderTop: "6px solid black",
      top: "14px",
      right: "12px",
      zIndex: 1,
    },
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    alignItems: "center",
    display: "flex",
    ":before": {
      backgroundColor: data.color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
};

export const titleStyles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #fff",
    borderBottom: "1px solid black",
    borderRadius: "none",
    fontFamily: "Oswald",
    color: "#000000",
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    ":hover": {
      border: "1px solid #fff",
      borderBottom: "1px solid black",
      borderRadius: "none",
    },
    ":focus": {
      outline: "none",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    opacity: 0,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    fontFamily: "Oswald",
    zIndex: 2,
  }),
  option: (styles, state) => ({
    ...styles,
    background: state.isSelected ? "#297A8E" : "white",
    textAlign: "left",
    ":hover": {
      background: "#5eaba1",
      color: "#ffffff",
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    margin: "0px",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "2px 0px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "black",
    fontSize: "20px",
    content: '" "',
    display: "flex",
    ":hover": {
      color: "blue",
    },
    ":before": {
      content: '" "',
      position: "absolute",
      border: "6px solid white",
      borderTop: "6px solid black",
      top: "42%",
      right: "12px",
      zIndex: 1,
    },
  }),
};

export const MatutinoPortadaGeneralCont = styled.div`
  .cont {
    display: flex;
    flex-direction: colum;
    margin: 0 10%;
    form {
      width: 100%;
    }
    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-semaforos {
        display: flex;
        flex-direction: column;
        &-title {
          display: flex;
          h3 {
            font-size: 26px;
            font-family: "Oswald";
            text-align: left;
          }
        }
        &-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin: 10px 0;
          &-input {
            height: 38px;
            display: flex;
            width: 65%;
          }
          &-radio {
            display: flex;
            justify-content: space-between;
            width: 30%;
            height: 38px;
            align-items: center;
            .input-radio {
              display: flex;
              align-items: center;
              p {
                margin-left: 10px;
                font-size: 15px;
                font-family: "Oswald";
              }
            }
          }
        }
      }
      .errorReport {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .error {
          color: #fa4443;
          font-family: "Oswald";
          font-size: 20px;
        }
      }
      .breadcrumb {
        text-align: left;
        span {
          font-family: "Oswald";
          font-size: 72px;
        }
      }
      &-pieGeneral {
        &-title {
          font-family: "Oswald";
          text-align: left;
          h2 {
            font-size: 26px;
            margin-top: 0;
            margin-bottom: 35px;
            color: #2f2f2f;
          }
        }
        &-selects {
          display: flex;
          flex-direction: column;
          &-select {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 35px;
            .title {
              width: 70%;
            }
            .color {
              width: 13%;
            }
            .porcentaje {
              width: 7%;
            }
            &-button {
              display: flex;
              position: relative;
              .dropdown {
                position: absolute;
                border: 6px solid transparent;
                border-top: 6px solid black;
                right: 3px;
                top: 15px;
                z-index: -2;
              }
              .porcent {
                position: absolute;
                right: 3px;
                top: 7px;
                background: white;
                color: black;
                font-family: "Oswald";
                font-size: 15px;
                cursor: pointer;
              }
              input {
                background: none;
                border: none;
                width: 100%;
                font-size: 15px;
                font-family: "Oswald";
                cursor: pointer;
                border-bottom: 1px solid #000000;
                &:focus {
                  outline: none;
                }
              }
              select {
                background: none;
                border: none;
                width: 100%;
                font-size: 15px;
                font-family: "Oswald";
                cursor: pointer;
                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
      &-fotoCover {
        display: flex;
        flex-direction: row;
        height: 210px;
        justify-content: space-between;
        margin-bottom: 30px;
        &-contButton {
          display: flex;
          flex-direction: column;
          &-title {
            text-align: left;
            margin-bottom: auto;
            h2 {
              font-family: "Oswald";
              color: #2f2f2f;
              font-size: 36px;
              margin: 0;
            }
          }
          &-button {
            border: 3px solid #235789;
            width: 320px;
            height: 92px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .upload {
              position: absolute;
              font-family: "Oswald";
              font-size: 42px;
              color: #235789;
            }
            input[type="file"] {
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
        }
        &-img {
          background: url("${(props) => props.imgFile}") center center no-repeat;
          background-size: cover;
          height: 100%;
          width: 70%;
          margin-left: auto;
        }
      }
      &-textarea {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        &-title {
          h2 {
            font-size: 26px;
            font-family: "Oswald";
            text-align: left;
          }
        }
        &-contTexarea {
          display: flex;
          border: 2px solid black;
          textarea {
            min-width: 98%;
            font-family: "Open Sans";
            font-size: 20px;
            color: #1f2833;
            margin: 10px;
            border: none;
            &:focus {
              outline: none;
            }
          }
        }
      }
      &-button {
        display: flex;
        margin: 50px 0;
        input[type="submit"] {
          color: white;
          font-family: "Oswald";
          font-size: 42px;
          background: #2f2f2f;
          width: 100%;
          border: none;
          border-radius: none;
          height: 92px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;
export const MatutinoPortadaStatic = styled.div`
  .cont {
    display: flex;
    flex-direction: colum;
    margin: 0 10%;
    form {
      width: 100%;
    }
    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-semaforos {
        display: flex;
        flex-direction: column;
        &-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin: 10px 0;
          &-input {
            height: 38px;
            display: flex;
            width: 65%;
          }
          &-radio {
            display: flex;
            justify-content: space-between;
            width: 30%;
            height: 38px;
            align-items: center;
            .input-radio {
              display: flex;
              align-items: center;
              p {
                margin-left: 10px;
                font-size: 15px;
                font-family: "Oswald";
              }
            }
          }
        }
      }
      .breadcrumb {
        text-align: left;
        span {
          font-family: "Oswald";
          font-size: 72px;
        }
      }
      .errorReport {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .error {
          color: #fa4443;
          font-family: "Oswald";
          font-size: 20px;
        }
      }
      &-pieGeneral {
        &-title {
          font-family: "Oswald";
          text-align: left;
          h2 {
            font-size: 26px;
            margin-top: 0;
            margin-bottom: 35px;
            color: #2f2f2f;
          }
        }
        &-selects {
          display: flex;
          flex-direction: column;
          &-select {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 35px;
            .title {
              width: 70%;
            }
            .color {
              width: 13%;
            }
            .porcentaje {
              width: 7%;
            }
            &-button {
              display: flex;
              position: relative;
              .dropdown {
                position: absolute;
                border: 6px solid transparent;
                border-top: 6px solid black;
                right: 3px;
                top: 15px;
                z-index: -2;
              }
              .porcent {
                position: absolute;
                right: 3px;
                top: 7px;
                background: white;
                color: black;
                font-family: "Oswald";
                font-size: 15px;
                cursor: pointer;
              }
              input {
                background: none;
                border: none;
                width: 100%;
                font-size: 15px;
                font-family: "Oswald";
                cursor: pointer;
                border-bottom: 1px solid #000000;
                &:focus {
                  outline: none;
                }
              }
              select {
                background: none;
                border: none;
                width: 100%;
                font-size: 15px;
                font-family: "Oswald";
                cursor: pointer;
                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
      &-fotoCover {
        display: flex;
        flex-direction: row;
        height: 210px;
        justify-content: space-between;
        margin-bottom: 30px;
        &-contButton {
          display: flex;
          flex-direction: column;
          &-title {
            text-align: left;
            margin-bottom: auto;
            h2 {
              font-family: "Oswald";
              color: #2f2f2f;
              font-size: 36px;
              margin: 0;
            }
          }
          &-button {
            border: 3px solid #235789;
            width: 320px;
            height: 92px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .upload {
              position: absolute;
              font-family: "Oswald";
              font-size: 42px;
              color: #235789;
            }
            input[type="file"] {
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
        }
        &-img {
          background: url("${(props) => props.imgFile}") center center no-repeat;
          background-size: cover;
          height: 100%;
          width: 70%;
          margin-left: auto;
        }
      }
      &-textarea {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        &-title {
          h2 {
            font-size: 26px;
            font-family: "Oswald";
            text-align: left;
          }
        }
        &-contTexarea {
          display: flex;
          border: 2px solid black;
          textarea {
            min-width: 98%;
            font-family: "Open Sans";
            font-size: 20px;
            color: #1f2833;
            margin: 10px;
            border: none;
            &:focus {
              outline: none;
            }
          }
        }
      }
      &-button {
        display: flex;
        margin: 50px 0;
        input[type="submit"] {
          color: white;
          font-family: "Oswald";
          font-size: 42px;
          background: #2f2f2f;
          width: 100%;
          border: none;
          border-radius: none;
          height: 92px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;
