import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client"
import client from "./config/apollo"

ReactDOM.render(<ApolloProvider client={client}><Router /></ApolloProvider>, document.getElementById("root"));

serviceWorker.unregister();
