import React from "react";
import styled from "styled-components";
import BackButton from "../components/BackButton";
import VerticalText from "../components/VerticalText";
import MatutinoStructure from "../components/MatutinoStructure";
import PostButton from "../components/PostButton";

const MatutinoCont = styled.div`
  display: flex;
`;

const Matutino = () => {
  return (
    <MatutinoCont>
      <BackButton urlBack="/" />
      <VerticalText text="matutino" />
      <MatutinoStructure
        portadaUrl="/matutino/portada"
        opinionUrl="/matutino/opinion"
        articulosUrl="/matutino/articulos"
        encabezadosUrl="/matutino/encabezados"
      />
      <PostButton text="Publicar" ulrPost="" />
    </MatutinoCont>
  );
};
export default Matutino;
