import {
  crearCliente
} from "./clientGenerator";
const {
  gql
} = require("@apollo/client");
const client = crearCliente();
const obtenerArticulos = (datos) => {
  let {
    palabras,
    estado,
    tipo_medio,
    nombre_medio,
    fechaInicio,
    fechaFinal,
    clasificacion,
    ambito
  } = datos;
  return client.query({
    query: gql `query {
      buscarArticulos(
        palabras: "${palabras}"
        estado: ${estado}
        tipo_medio: ${tipo_medio}
        nombre_medio: ${JSON.stringify(nombre_medio)}
        ambito: ${JSON.stringify(ambito)}
        fechaInicio: "${fechaInicio}"
        fechaFinal: "${fechaFinal}"
        clasificacion: "${clasificacion}"
      ) {
        titulo
        medio
        tipo_medio
        texto
        nombre_medio
        testigo
      }
    }
  `,
  });
};

export {
  obtenerArticulos
};