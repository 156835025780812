let API = "https://intelicast.net/Cygni/clasificacion_medios.php";
const nombres_de_medios = () => {
  return new Promise((resolve, reject) => {
    fetch(API)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        let results = [{ nombre: "Todos", clave: 0 }];
        for (let r of res) {
          results.push({ nombre: r.descripcion, clave: r.clave });
        }
        if (results.length > 1) {
          resolve(results);
        }
        resolve([]);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

export { nombres_de_medios };
