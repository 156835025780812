import React, { useState, useRef } from "react";
import { Container } from "../components/modules";
import { Controller } from "react-hook-form";
import ButtonSubmit from "../components/ButtonSubmit";
import { titleStyles } from "../styles/styles";
import { useForm } from "react-hook-form";
import { categoria as Valores } from "../InformacionParaArticulos/clasificacion";
import InputText from "../components/InputText";
import ImgFileDummy from "../assets/img/dummy-picture.jpg";
import { NuevaSintesis } from "./Estilos/ArticulosEstilos";
import { actualizarArticulo } from "../consumo_apollo_client/clienteArticulos";
import "bootstrap/dist/css/bootstrap.min.css";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import TestigosTags from "../components/TestigosTags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const MatutinoArticulos = ({ info, actualizar, cerrar }) => {
  //Hooks
  const inputFileRef = useRef(null);
  const [imgFile, setImgFile] = useState(ImgFileDummy);
  const [item, setItem] = React.useState(info);
  const [editor, setEditor] = React.useState(
    EditorState.createWithContent(convertFromRaw(JSON.parse(info.cuerpo)))
  );

  function convertImgToBase64(url, callback, outputFormat) {
    let canvas = document.createElement("CANVAS");
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL(outputFormat || "image/png");
      callback.call(this, dataURL);
      canvas = null;
    };
    img.src = url;
  }

  //Convierte imagen a base64
  const readURL = () => {
    setImgFile(undefined);
    if (inputFileRef.current.files && inputFileRef.current.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImgFile(e.target.result);
      };
      reader.readAsDataURL(inputFileRef.current.files[0]);
    }
  };

  const removerElemento = (index) => {
    let temparray = [];
    let tempitem = { ...item };
    for (let i = 0; i < item.testigos.length; i++) {
      if (i !== index) {
        temparray.push(item.testigos[i]);
      }
    }
    tempitem.testigos = temparray;
    setItem(tempitem);
  };

  //Agrega el articulo creado
  const onSubmitNuevaSintesis = (data) => {
    let record = {
      record: {
        _id: info._id,
        categoria: data.categoria,
        cuerpo: JSON.stringify(convertToRaw(editor.getCurrentContent())),
        photoURL: imgFile,
        palabrasClave: data.palabrasClave.split(","),
        subtitulo: data.subtitulo,
        testigos: item.testigos,
        titulo: data.titulo,
      },
    };
    actualizarArticulo(record)
      .then(() => {
        actualizar();
        alert("Articulo actualizado");
        cerrar();
      })
      .catch(() => {
        alert("Error al actualizar articulo");
      });
  };

  const onInvalidSubmit = (data) => {
    console.log(data);
  };

  //Validador form
  const {
    register: registerNS,
    control: controlNS,
    handleSubmit: handleSubmitNS,
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  //Poner la info al iniciar
  React.useEffect(() => {
    convertImgToBase64(info.photoURL, (R1) => {
      setImgFile(R1);
    });
    reset({
      titulo: info.titulo,
      subtitulo: info.subtitulo,
      testigos: info.testigos,
      categoria: info.categoria,
      cuerpo: info.cuerpo,
      palabrasClave: info.palabrasClave,
    });
  }, [reset, info]);

  //Componente
  return (
    <>
      <Container>
        <Container margin="0 0 80px 0">
          <NuevaSintesis imgFile={imgFile}>
            <div className="NSCont">
              <div className="NSCont-title">
                <h2>Modificar articulo</h2>
              </div>
              <div className="NSCont-row">
                <div className="NSCont-row-input w-65">
                  <InputText
                    name="titulo"
                    placeholder="Titulo"
                    register={registerNS}
                  />
                </div>
                <div className="NSCont-row-input w-30">
                  <select
                    styles={titleStyles}
                    ref={registerNS({ required: true })}
                    onChange={(val) => {
                      setValue("categoria", val.target.value);
                    }}
                    name="categoria"
                  >
                    {Valores.map((item, i) => {
                      return (
                        <option key={i} value={item.valor}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="NSCont-row">
                <div className="cont">
                  <div className="input">
                    <InputText
                      name="subtitulo"
                      placeholder="Subtítulo"
                      register={registerNS}
                    />
                  </div>
                  <div>
                    Testigos:
                    {
                      <TestigosTags
                        tags={item.testigos}
                        remove={removerElemento}
                      />
                    }
                  </div>
                </div>
                <div className="NSCont-row-textarea">
                  <textarea
                    name="palabrasClave"
                    cols="30"
                    rows="4"
                    placeholder="Palabras clave"
                    ref={registerNS}
                  ></textarea>
                </div>
              </div>
              <div className="cont-form-textarea">
                <div className="cont-form-textarea-contTexarea">
                  <Controller
                    as={Editor}
                    editorState={editor}
                    onEditorStateChange={setEditor}
                    name="cuerpo"
                    control={controlNS}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
              </div>
              <div className="NSCont-row">
                <div className="NSCont-row-fotoCover">
                  <div className="NSCont-row-fotoCover-contButton">
                    <div className="NSCont-row-fotoCover-contButton-title">
                      <h2>Foto Cover</h2>
                    </div>
                    <div className="NSCont-row-fotoCover-contButton-button">
                      <span className="upload">Subir archivo</span>
                      <input
                        ref={(e) => {
                          inputFileRef.current = e;
                        }}
                        name="photoURL"
                        id="test1"
                        type="file"
                        onChange={readURL}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                  <div className="NSCont-row-fotoCover-img"></div>
                </div>
              </div>
              <ButtonSubmit
                text="Guardar"
                onClick={handleSubmitNS(onSubmitNuevaSintesis, onInvalidSubmit)}
              />
            </div>
          </NuevaSintesis>
        </Container>
      </Container>
    </>
  );
};
export default MatutinoArticulos;
