import React, { createContext, useState } from 'react'
import { currentUser } from '../services/auth'

export const Context = createContext()

export default function ContextProvider({ children }) {
  const [userCtx, setUserCtx] = useState(() => getUserData())
  
  async function getUserData() {
    const user = await currentUser()
    setUserCtx(user)
  }
  
  const loginCtx = userData => setUserCtx(userData)
  const logoutCtx = () => setUserCtx(null)

  return (
    <Context.Provider value={{
      userCtx,
      loginCtx,
      logoutCtx
    }}>
      { children }
    </Context.Provider>
  )
}
