import axios from 'axios'

const baseURL = process.env.REACT_APP_SERVER_ENDPOINT

const service = axios.create({ baseURL, withCredentials: true })

export const signup = async userData => {
  await service.post("/signup", userData)
  return true
}

export const login = async userData => {
  const { data: user } = await service.post("/login", userData)
  return user
}

export const currentUser = async () => {
  const { data: user } = await service.get('/currentuser')
  return user
}

export const logout = async () => {
  await service.get('/logout')
  return true
}
