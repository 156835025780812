export const clasificacion = [
  {
    nombre: "Todos",
    clave: "0",
  },
  {
    nombre: "Internacionales",
    clave: "3",
  },
  {
    nombre: "Estatales",
    clave: "2",
  },
  {
    nombre: "Nacionales",
    clave: "1",
  },
];

export const categoria = [
  {
    nombre: "Economía y Finanzas",
    clave: "Economia",
  },
  {
    nombre: "Politica",
    clave: "Politica",
  },
  {
    nombre: "Agenda",
    clave: "Agenda",
  },
  {
    nombre: "Seguridad y Sociedad",
    clave: "Sociedad",
  },
  {
    nombre: "Internacional",
    clave: "Internacional",
  },
];

export const tipos = [
  {
    nombre: "General",
    clave: "Agenda",
  },
  {
    nombre: "Política",
    clave: "Politica",
  },
  {
    nombre: "Economía",
    clave: "Economia",
  },
];
