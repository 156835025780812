const {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} = require("@apollo/client");
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}


function crearCliente() {
  const authLink = new ApolloLink((operation, forward) => {
    const token = "EDITOR_DEV";
    operation.setContext({
      headers: {
        authorization: token,
      },
    });
    return forward(operation);
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    fetchOptions: {
      mode: 'no-cors',
    },
    defaultOptions: defaultOptions
  });
  return client;
}

export {
  crearCliente
};