import React from "react";
import styled from "styled-components";
import BackButton from "../components/BackButton";
import VerticalText from "../components/VerticalText";
import MatutinoStructure from "../components/MañaneraStructure";

const MatutinoCont = styled.div`
  display: flex;
`;

const Matutino = () => {
  return (
    <MatutinoCont>
      <BackButton urlBack="/" />
      <VerticalText text="Mañanera" />
      <MatutinoStructure
        portadaUrl="/mañanera/Portada"
        bulletsUrl="/mañanera/Bullets"
      />
    </MatutinoCont>
  );
};
export default Matutino;
