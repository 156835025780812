import React from "react";
import styled from "styled-components";

const VerticalTextCont = styled.div`
  position: relative;
  width: 300px;
  .verticalText {
    position: relative;
    height: 100vh;
    text-align: center;
    h2 {
      text-transform: uppercase;
      font-family: "Oswald";
      font-size: 224px;
      transform: rotate(180deg);
      margin: 0;
      writing-mode: vertical-lr;
      height: 100vh;
      color: ${(props) => props.colorText};
      font-weight: 400;
      opacity: 0.8;
    }
  }
`;

const VerticalText = ({ text = "Dummy", colorText = "#000000" }) => {
  return (
    <VerticalTextCont colorText={colorText}>
      <div className="verticalText">
        <h2> {text} </h2>
      </div>
    </VerticalTextCont>
  );
};

export default VerticalText;
