import React from "react";
import styled from "styled-components";
import Button from "./Button";

const MatutinoPortadaStructureCont = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .matutinoStrucCont {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    min-height: 600px;
    margin: auto;
    &-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      .p {
        font-size: 26px;
      }
    }
  }
`;

const MatutinoPortadaStructure = ({
  general,
  generalUrl,
  economia,
  economiaUrl,
  social,
  socialUrl,
  internacional,
  internacionalUrl,
}) => {
  return (
    <MatutinoPortadaStructureCont>
      <div className="matutinoStrucCont">
        <div className="matutinoStrucCont-button">
          <Button text="general" url={generalUrl} />
        </div>
        <div className="matutinoStrucCont-button">
          <Button text="Economía" url={economiaUrl} />
        </div>
        <div className="matutinoStrucCont-button">
          <Button text="Social" url={socialUrl} />
        </div>
        <div className="matutinoStrucCont-button">
          <Button text="Internacional" url={internacionalUrl} />
        </div>
      </div>
    </MatutinoPortadaStructureCont>
  );
};
export default MatutinoPortadaStructure;
