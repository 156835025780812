import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PostButtonCont = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 20vh;
  background: rgba(157, 157, 159, 0.2);
  .postButtonCont {
    margin-left: 400px;
    height: 100%;
    display: flex;
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      a {
        margin: auto;
        text-decoration: none;
        display: flex;
        button {
          width: 710px;
          height: 92px;
          background: #2f2f2f;
          border: none;
          color: white;
          padding: 0;
          font-size: 42px;
          font-family: "Oswald";
          font-weight: 400;
          cursor: pointer;
          &:disabled {
            opacity: 0.5;
            cursor: auto;
          }
        }
      }
    }
  }
`;

const PostButton = ({ text, ulrPost }) => {
  return (
    <PostButtonCont>
      <div className="postButtonCont">
        <div className="postButtonCont-button">
          <Link to={ulrPost}>
            <button type="button"> {text} </button>
          </Link>
        </div>
      </div>
    </PostButtonCont>
  );
};

export default PostButton;
