import React from "react";
import styled from "styled-components";

const InputTextCont = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #000000;
  input {
    font-family: "Oswald";
    font-size: 15px;
    width: 100%;
    border: none;
    color: #000000;
    &:focus {
      outline: none;
    }
  }
`;

const InputText = ({
  name,
  register,
  placeholder = "Escribe texto",
  type = "text",
  value,
  disabled,
}) => {
  return (
    <InputTextCont>
      <input
        type={type}
        name={name}
        ref={register}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    </InputTextCont>
  );
};
export default InputText;
