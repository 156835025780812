import React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Input from "@material-ui/core/Input";
import ButtonSubmit from "../components/ButtonSubmit";
import styled from "styled-components";
import InputRadio from "../components/InputRadio";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TestStyled = styled.div`
  display: flex;
  .red-border {
    border: none;
    border-bottom: 2px solid red;
    height: 30px;
    font-family: "Oswald";
    font-size: 15px;
    &:focus {
      outline: none;
    }
  }
  .inputRadioYellow {
    display: flex;
    .radioYellow {
      height: 20px;
      width: 20px;
      background: #9d9d9f;
      border: 2px solid #9d9d9f;
      border-radius: 50%;
      transition: ease-in-out 0.2s;
      cursor: pointer;
    }
    .radioYellowDNone {
      display: none;
      &:checked + label {
        background: #ffe061;
        border: 2px solid black;
      }
    }
  }
  .inputRadioRed {
    display: flex;
    .radioRed {
      height: 20px;
      width: 20px;
      background: #9d9d9f;
      border: 2px solid #9d9d9f;
      border-radius: 50%;
      transition: ease-in-out 0.2s;
      cursor: pointer;
    }
    .radioRedDNone {
      display: none;
      &:checked + label {
        background: #fa4443;
        border: 2px solid black;
      }
    }
  }
  .inputRadioGreen {
    display: flex;
    .radioGreen {
      height: 20px;
      width: 20px;
      background: #9d9d9f;
      border: 2px solid #9d9d9f;
      border-radius: 50%;
      transition: ease-in-out 0.2s;
      cursor: pointer;
    }
    .radioGreenDNone {
      display: none;
      &:checked + label {
        background: #5eaba1;
        border: 2px solid black;
      }
    }
  }
`;

export default function Test() {
  const { control, handleSubmit, errors, register } = useForm();
  const { control: control2, handleSubmit: handleSubmit2, errors: errors2, register: register2 } = useForm();
  const Hola = () => console.log("Holaaaa");
  const onSubmit = (data) => ({
    nombre: data.HelloWorld,
    email: data.AntdInput,
    text: data.ReactSelect.value,
  });
  const onSubmit2 = (data) => ({
    nombre: data.HelloWorld,
    email: data.AntdInput,
    text: data.ReactSelect.value,
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: "1px solid #fff",
      borderBottom: "1px solid black",
      borderRadius: "none",
      fontFamily: "Oswald",
      color: "#000000",
      cursor: "pointer",
      boxShadow: "none",
      ":hover": {
        border: "1px solid #fff",
        borderBottom: "1px solid black",
        borderRadius: "none",
      },
      ":focus": {
        outline: "none",
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      opacity: 0,
    }),
    menu: (styles) => ({
      ...styles,
      margin: 0,
      fontFamily: "Oswald",
      zIndex: 2,
    }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected ? "black" : "white",
      textAlign: "left",
      ":hover": {
        background: "blue",
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "black",
      fontSize: "20px",
      content: '" "',
      display: "flex",
      ":hover": {
        color: "blue",
      },
      ":before": {
        content: '" "',
        position: "absolute",
        border: "6px solid white",
        borderTop: "6px solid black",
        top: "14px",
        right: "12px",
        zIndex: 1,
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      alignItems: "center",
      display: "flex",
      ":before": {
        backgroundColor: data.color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    }),
  };
  const options = [
    { value: "chocolate", label: "Chocolate", color: "#ffff00" },
    { value: "strawberry", label: "Strawberry", color: "#ff0000" },
    { value: "vanilla", label: "Vanilla", color: "#ff00ff" },
  ];
  // const [selectedDate, setSelectedDate] = React.useState(
  //   new Date("2014-08-18T21:11:54")
  // );

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  return (<>
    <form onSubmit={handleSubmit(onSubmit)}>
      {errors.HelloWorld && "Your input required to be more than 50"}
      <Controller
        as={Input}
        name="HelloWorld"
        control={control}
        defaultValue=""
        rules={{ required: true }}
      />
      {errors.ReactSelect && "Yasdasd"}
      <Controller
        as={Select}
        options={options}
        name="ReactSelect"
        control={control}
        styles={colourStyles}
        placeholder="Hola"
        rules={{
          required: true,
        }}
      />
      <InputRadio
        color="#FFE061"
        register={register}
        name="radio1"
        forId="radioYellow2"
        value="Jotillos"
      />
      <TestStyled>
        <div className="inputRadioRed">
          <input
            type="radio"
            name="radio1"
            id="radioRed1"
            value=""
            className="radioRedDNone"
            ref={register}
          />
          <label htmlFor="radioRed1" className="radioRed"></label>
        </div>
        <div className="inputRadioYellow">
          <input
            type="radio"
            name="radio1"
            id="radioYellow1"
            value=""
            className="radioYellowDNone"
            ref={register}
          />
          <label htmlFor="radioYellow1" className="radioYellow"></label>
        </div>
        <div className="inputRadioGreen">
          <input
            type="radio"
            name="radio1"
            id="radioGreen1"
            value={options}
            className="radioGreenDNone"
            ref={register}
          />
          <label htmlFor="radioGreen1" className="radioGreen"></label>
        </div>
        <Controller
          control={control}
          name="ReactDatepicker"
          render={(props) => (
            <ReactDatePicker
              className="input red-border"
              placeholderText="Select date"
              onChange={(e) => props.onChange(e)}
              selected={props.value}
            // className='red-border'
            />
          )}
        />
      </TestStyled>
      <input type="checkbox" name="nombre" value='1' ref={register} />
      <input type="checkbox" name="nombre" value='2' ref={register} />
      <input type="checkbox" name="nombre" value='3' ref={register} />
      <input type="checkbox" name="nombre" value='4' ref={register} />
      <ButtonSubmit text="Guardar" onClick={Hola} />
    </form>




    <form onSubmit={handleSubmit2(onSubmit2)}>
      {errors2.HelloWorld && "Your input required to be more than 50"}
      <Controller
        as={Input}
        name="HelloWorld"
        control={control2}
        defaultValue=""
        rules={{ required: true }}
      />
      {errors2.ReactSelect && "Yasdasd"}
      <Controller
        as={Select}
        options={options}
        name="ReactSelect"
        control={control2}
        styles={colourStyles}
        placeholder="Hola"
        rules={{
          required: true,
        }}
      />
      <InputRadio
        color="#FFE061"
        register={register2}
        name="radio1"
        forId="radioYellow2"
        value="Jotillos"
      />
      <TestStyled>
        <div className="inputRadioRed">
          <input
            type="radio"
            name="radio1"
            id="radioRed1"
            value=""
            className="radioRedDNone"
            ref={register2}
          />
          <label htmlFor="radioRed1" className="radioRed"></label>
        </div>
        <div className="inputRadioYellow">
          <input
            type="radio"
            name="radio1"
            id="radioYellow1"
            value=""
            className="radioYellowDNone"
            ref={register2}
          />
          <label htmlFor="radioYellow1" className="radioYellow"></label>
        </div>
        <div className="inputRadioGreen">
          <input
            type="radio"
            name="radio1"
            id="radioGreen1"
            value={options}
            className="radioGreenDNone"
            ref={register2}
          />
          <label htmlFor="radioGreen1" className="radioGreen"></label>
        </div>
        <Controller
          control={control2}
          name="ReactDatepicker"
          render={(props) => (
            <ReactDatePicker
              className="input red-border"
              placeholderText="Select date"
              onChange={(e) => props.onChange(e)}
              selected={props.value}
            />
          )}
        />
      </TestStyled>
      <input type="checkbox" name="nombre" value='1' ref={register2} />
      <input type="checkbox" name="nombre" value='2' ref={register2} />
      <input type="checkbox" name="nombre" value='3' ref={register2} />
      <input type="checkbox" name="nombre" value='4' ref={register2} />
      <ButtonSubmit text="Guardar" onClick={Hola} />
    </form>
  </>);
}
