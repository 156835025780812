import React from "react";
import BackButton from "../components/BackButton";
import FormularioPortada from "./FormulariosPortada";
import { MatutinoPortadaGeneralCont } from "../styles/styles";

const MatutinoPortadaGeneral = () => {
  return (
    <MatutinoPortadaGeneralCont>
      <BackButton urlBack="/matutino/portada" position={true} />
      <FormularioPortada tipo={"Agenda"} />
    </MatutinoPortadaGeneralCont>
  );
};
export default MatutinoPortadaGeneral;
