import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Matutino from "./pages/Matutino";
import MatutinoPortada from "./pages/MatutinoPortada";
import MatutinoPortadaGeneral from "./pages/MatutinoPortadaGeneral";
import MatutinoPortadaEconomia from "./pages/MatutinoPortadaEconomia";
import MatutinoArticulos from "./pages/MatutinoArticulos";
import MatutinoEncabezados from "./pages/MatutinoEncabezados";
import MañaneraPortada from "./pages/Mañanera";
import MatutinoOpinion from "./pages/MatutinoOpinion";
import MatutinoBullets from "./pages/MañaneraBullets";
import MatutinoBulletsPortada from "./pages/MañaneraBulletsPortada";
import MatutinoPortadaSocial from "./pages/MatutinoPortadaSocial";
import MatutinoPortadaInternacional from "./pages/MatutinoPortadaInternacional";
import Login from "./pages/Login";
import Signup from "./pages/Signup"
import Test from "./pages/Test";
import CtxProvider from './Context/index';
import PrivateRoute from './components/PrivateRoute'

const Router = () => (
  <BrowserRouter>
    <CtxProvider>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/signup" component={Signup} />
        <PrivateRoute exact path="/matutino" component={Matutino} />


        <PrivateRoute
          exact 
          path="/matutino/portada" 
          component={MatutinoPortada} 
        />
        <PrivateRoute
          exact
          path="/matutino/portada/general"
          component={MatutinoPortadaGeneral}
        />
        <PrivateRoute
          exact
          path="/matutino/portada/economia"
          component={MatutinoPortadaEconomia}
        />
        <PrivateRoute
          exact
          path="/matutino/portada/social"
          component={MatutinoPortadaSocial}
        />
        <PrivateRoute exact path="/mañanera/bullets" component={MatutinoBullets} />
        <PrivateRoute
          exact
          path="/mañanera/portada"
          component={MatutinoBulletsPortada}
        />
        <PrivateRoute
          exact
          path="/matutino/portada/internacional"
          component={MatutinoPortadaInternacional}
        />
        <PrivateRoute exact path="/matutino/articulos" component={MatutinoArticulos} />
        <PrivateRoute
          exact
          path="/matutino/encabezados"
          component={MatutinoEncabezados}
        />
        <PrivateRoute exact path="/mañanera/" component={MañaneraPortada} />
        <PrivateRoute exact path="/matutino/opinion" component={MatutinoOpinion} />
        <PrivateRoute exact path="/test" component={Test} />
        <PrivateRoute path="*" component={Home} />
      </Switch>
    </CtxProvider>
  </BrowserRouter>
);

export default Router;