import styled from "styled-components";
const ArticulosCreados = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  .title {
    display: flex;
    h3 {
      margin: 0;
      font-family: "Oswald";
      font-size: 26px;
    }
  }
  .text {
    display: flex;
    margin-top: 30px;
    p {
      margin: 0;
      font-size: 15px;
    }
  }
`;

const NuevoArticulo = styled.div `
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: auto;
  .error {
    height: 18px;
    width: 100%;
    display: flex;
    p {
      margin: 0;
      color: #fa4443;
      font-size: 13px;
      font-family: "Oswald";
    }
  }
  .title {
    display: flex;
    h3 {
      font-family: "Oswald";
      font-size: 26px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .dateTime {
      display: flex;
      flex-direction: column;
      &-row {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        &-input {
          display: flex;
          width: 100%;
          border-bottom: 1px solid black;
          height: 35px;
          input {
            width: 100%;
            border: none;
            font-size: 15px;
            font-family: "Oswald";
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      &-row {
        display: flex;
        &-input {
          display: flex;
          width: 33.33%;
          margin: 10px 0;
          align-items: center;
          span {
            margin-left: 10px;
            font-family: "Oswald";
            font-size: 15px;
          }
        }
      }
    }
    .select {
      display: flex;
      flex-direction: column;
      .h-auto {
        height: auto;
      }
      &-row {
        display: flex;
        flex-direction: column;
        height: 40px;
        margin: 10px 0;
      }
    }
  }
`;
const SintetizarTabla = styled.div `
  background: rgba(157, 157, 159, 0.2);
  display: flex;
  flex-direction: column;
  width: 60%;
  .tablaHeader {
    display: flex;
    background: white;
    width: 100%;
    height: 68px;
    &-row {
      display: flex;
      padding: 0 50px;
      width: 100%;
      &-check {
        width: 40px;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-titulo {
        width: 65%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-medio {
        width: 20%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
      &-tipo {
        width: 15%;
        text-align: left;
        display: flex;
        p {
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .button {
    display: flex;
    padding: 50px;
  }
`;

const InputTagsStyles = styled.div `
  display: flex;
  width: 100%;
  .react-tagsinput {
    display: flex;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    border: 1px solid white;
    border-bottom: 1px solid black;
    background: white;
    height: auto;
    display: flex;
    span {
      display: flex;
      flex-wrap: wrap;
      .react-tagsinput-tag {
        font-family: "Oswald";
        font-style: normal;
        font-size: 15px;
        padding: 5px;
        background: #5eaba1;
        color: white;
        margin: 5px 5px 5px 0;
        border-radius: 5px;
        .react-tagsinput-remove {
          display: flex;
          width: 15px;
          text-align: center;
          cursor: pointer;
          &::before {
            display: flex;
            font-family: "Open Sans";
            font-size: 16px;
            content: "x";
            width: 15px;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
      input {
        font-family: "Oswald";
        font-style: normal;
        font-size: 15px;
        color: black;
        width: auto;
        background: white;
        padding: 5px 0;
        border: 0;
        margin: 5px 0;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-style: normal;
        }
      }
    }
  }
`;
const NuevaSintesis = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  .w-65 {
    width: 65%;
  }
  .w-30 {
    width: 30%;
  }
  .NSCont {
    display: flex;
    flex-direction: column;
    &-title {
      font-family: Oswald;
      font-size: 26px;
      text-align: left;
      h2 {
        margin-bottom: 20px;
        margin-top: 80px;
      }
    }
    &-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-input {
        height: 36px;
        display: flex;
      }
      &-textarea {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        textarea {
          width: 100%;
          height: auto;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid black;
          font-family: Oswald;
          font-size: 14px;
          &:focus {
            outline: none;
          }
        }
      }
      .cont {
        display: flex;
        flex-direction: column;
        width: 65%;
        height: auto;
        margin-bottom: 20px;
        .input {
          width: 100%;
          height: 36px;
        }
        .input:nth-child(1) {
          margin-bottom: 20px;
        }
      }
      .textareaAll {
        border: 2px solid black;
        min-width: 100%;
        min-height: 400px;
        font-family: Oswald;
        font-size: 18px;
        box-sizing: border-box;
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
      &-fotoCover {
        display: flex;
        flex-direction: row;
        height: 210px;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        &-contButton {
          display: flex;
          flex-direction: column;
          &-title {
            text-align: left;
            margin-bottom: auto;
            h2 {
              font-family: "Oswald";
              color: #2f2f2f;
              font-size: 36px;
              margin: 0;
            }
          }
          &-button {
            border: 3px solid #235789;
            width: 320px;
            height: 92px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .upload {
              position: absolute;
              font-family: "Oswald";
              font-size: 42px;
              color: #235789;
            }
            input[type="file"] {
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
        }
        &-img {
          background: url("${(props) => props.imgFile}") center center no-repeat;
          background-size: cover;
          height: 210px;
          width: 70%;
          margin-left: auto;
        }
      }
    }
  }
`;
const tabla = styled.div `
  width: 100%;
  background-color: #f3f3f3;

  tbody {
      height: 200px;
      overflow-y: auto;
      width: 100%;
  }

  thead,
  tbody,
  tr,
  td,
  th {
      display: block;
  }

  tbody {
      td {
          float: left;
      }
  }

  thead {
      tr {
          th {
              float: left;
              background-color: #f39c12;
              border-color: #e67e22;
          }
      }
  }
`;


export {
  ArticulosCreados,
  NuevoArticulo,
  SintetizarTabla,
  NuevaSintesis,
  InputTagsStyles,
  tabla
}